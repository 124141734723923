<template>
	<v-container>
		<v-container v-if="!requestQuotePressed">
			<v-tabs
				v-model="tab"
				background-color="transparent"
				color="basil"
				grow
			>
				<v-tab
					v-for="(item,index) in items"
					:key="index"
					:disabled="!(index<=progress)"
				>
					{{ item.label }}
				</v-tab>
			</v-tabs>	
			<v-container v-if="isStep(0)">
				<v-row>
					<v-col v-for="(item,index) in firstRow" v-bind:key="'1'+index" cols=3 >
						<a href="#" @click="selection(item)">
							<v-img
							alt="Vuetify Logo"
							contain
							:src="item.image"
							transition="scale-transition"
							width="140px"
							:style="item.style"
							/>
							<h3 v-if="data.quoteFor===item.label" style="margin-top:15px;margin-left:25px;background-color:red;text-decoration-color: none !important; color: white;">{{item.label}}</h3>
							<h3 v-if="data.quoteFor!==item.label" style="margin-top:15px;margin-left:25px;color: black;">{{item.label}}</h3>
						</a>
					</v-col>
				</v-row>
				<v-row>
					<v-col v-for="(item,index) in secondRow" v-bind:key="'2'+index" cols=3 >
						<a href="#" @click="selection(item)">
							<v-img
							alt="Vuetify Logo"
							contain
							:src="item.image"
							transition="scale-transition"
							width="140px"
							:style=item.style
							/>
							<h3 v-if="data.quoteFor===item.label" style="margin-top:15px;margin-left:25px;background-color:red;text-decoration-color: none !important; color: white;">{{item.label}}</h3>
							<h3 v-if="data.quoteFor!==item.label" style="margin-top:15px;margin-left:25px;color: black;">{{item.label}}</h3>						
						</a>
					</v-col>	
				</v-row>
				<v-row>
					<v-col v-for="(item,index) in thirdRow" v-bind:key="'3'+index" cols=3 >
						<a href="#" @click="selection(item)">
							<v-img
							alt="Vuetify Logo"
							contain
							:src="item.image"
							transition="scale-transition"
							width="140px"
							:style=item.style
							/>
							<h3 v-if="data.quoteFor===item.label" style="margin-top:15px;margin-left:25px;background-color:red;text-decoration-color: none !important; color: white;">{{item.label}}</h3>
							<h3 v-if="data.quoteFor!==item.label" style="margin-top:15px;margin-left:25px;color: black;">{{item.label}}</h3>
						</a>
					</v-col>	
				</v-row>
				<v-row>
					<v-col v-for="(item,index) in fourthRow" v-bind:key="'4'+index" cols=3 >
						<a href="#" @click="selection(item)">
							<v-img
							alt="Vuetify Logo"
							contain
							:src="item.image"
							transition="scale-transition"
							width="140px"
							:style=item.style
							/>
							<h3 v-if="data.quoteFor===item.label" style="margin-top:15px;margin-left:25px;background-color:red;text-decoration-color: none !important; color: white;">{{item.label}}</h3>
							<h3 v-if="data.quoteFor!==item.label" style="margin-top:15px;margin-left:25px;color: black;">{{item.label}}</h3>
						</a>
					</v-col>	
				</v-row>				
				<v-row>
					<v-col v-for="(item,index) in fifthRow" v-bind:key="'5'+index" cols=3>
						<a href="#" @click="selection(item)">
							<v-img
							alt="Vuetify Logo"
							contain
							:src="item.image"
							transition="scale-transition"
							width="140px"
							:style=item.style
							/>
							<h3 v-if="data.quoteFor===item.label" style="margin-top:15px;margin-left:25px;background-color:red;text-decoration-color: none !important; color: white;">{{item.label}}</h3>
							<h3 v-if="data.quoteFor!==item.label" style="margin-top:15px;margin-left:25px;color: black;">{{item.label}}</h3>
						</a>
					</v-col>	
				</v-row>
			</v-container>
			
			<v-container v-if="isStep(1)&&data.quoteFor.installtype==='Automotive'">
				<v-row style="margin-top:35px">
					<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								class="mx-2"
								fab
								dark
								color="success"
								v-bind="attrs"
								v-on="on"
								@click="tab=2;progress=2;"
							>
								<v-icon dark>
									mdi-arrow-right
								</v-icon>							
							</v-btn>
						</template>
						<span>Contact Details</span>
					</v-tooltip>
					<a @click="tab=2;progress=2;"><h3 style="margin-top:15px;">Click here for the last step - Contact details</h3></a>
				</v-row>
				<v-row style="margin-top:30px;">
					<div>
						<v-img
						alt="Vuetify Logo"
						contain
						:src="data.quoteFor.image"
						transition="scale-transition"
						width="140px"
						:style="data.quoteFor.style"
						/>
					</div>
				</v-row>
				<div style="margin-top:30px;background-color:maroon;color:white">
					{{ data.quoteFor.label }}
				</div>
				<v-container>
					<v-row style="margin-top:20px;">
						<h4>Tint</h4>
					</v-row>
					<v-row>
						<v-btn-toggle v-model="data.premium"  mandatory>
							<v-btn @click="data.premium=0">
								Regular
							</v-btn>
							<v-btn @click="data.premium=1">
								Premium
							</v-btn>
						</v-btn-toggle>		
					</v-row>
					<v-row style="margin-top:20px;">
						<h4>Removal of existing tint</h4>
					</v-row>
					<v-row>
						<v-btn-toggle v-model="data.tintremoval"  mandatory>
							<v-btn @click="data.tintremoval=0">
								Not Required
							</v-btn>
							<v-btn @click="data.tintremoval=1">
								Required
							</v-btn>
						</v-btn-toggle>		
					</v-row>
					<v-row style="margin-top:20px;" v-if="false">
						<h4>Install location</h4>
					</v-row>
					<v-row v-if="false">
						<v-btn-toggle v-model="data.installlocation" mandatory>
							<v-btn @click="data.installlocation=0">
								Soalrtint Penrith
							</v-btn>
							<v-btn @click="data.installlocation=2" v-if="false">
								My Place
							</v-btn>
						</v-btn-toggle>		
					</v-row>
				</v-container>
				<v-row style="margin-top:35px">
					<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								class="mx-2"
								fab
								dark
								color="success"
								v-bind="attrs"
								v-on="on"
								@click="tab=2;progress=2;"
							>
								<v-icon dark>
									mdi-arrow-right
								</v-icon>							
							</v-btn>
						</template>
						<span>Contact Details</span>
					</v-tooltip>
					<a @click="tab=2;progress=2;"><h3 style="margin-top:15px;">Click here for the last step - Contact details</h3></a>
				</v-row>
				<div style="margin-top:30px;background-color:maroon;color:white">
					More Information (Optional)
				</div>
				<p/>
				<v-container>
					<v-combobox
						v-model="data.manufacturer"
						:counter="100"
						:error-messages="errors"
						:items="manufacturers"
						label="Manufacturer"
						required
						prepend-inner-icon="mdi-car"
					></v-combobox>
						<v-row>
							<v-col cols=8>
								<v-text-field
									v-model="data.model"
									:counter="100"
									:error-messages="errors"
									label="Model"
									required
								></v-text-field>			
							</v-col>						
							<v-col cols=4>
								<v-select
									v-model="data.year"
									:items="years"
									:error-messages="errors"
									label="Make Year"
									data-vv-name="year"
									required
								></v-select>	
							</v-col>						
						</v-row>
				</v-container>
				<v-container>
					<v-textarea
						v-model="data.comment"
						label="Additional information that will help with a quote"
					></v-textarea>	
				</v-container>
					
				<v-row>
					<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								class="mx-2"
								fab
								dark
								color="success"
								v-bind="attrs"
								v-on="on"
								@click="tab=2;progress=2;"
							>
								<v-icon dark>
									mdi-arrow-right
								</v-icon>							
							</v-btn>
						</template>
						<span>Contact Details</span>
					</v-tooltip>
					<a @click="tab=2;progress=2;"><h3 style="margin-top:15px;">Click here for the last step - Contact details</h3></a>
				</v-row>
			</v-container>

			<v-container v-if="isStep(1)&&data.quoteFor.installtype!=='Automotive'">
				<v-row style="margin-top:35px">
					<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								class="mx-2"
								fab
								dark
								color="success"
								v-bind="attrs"
								v-on="on"
								@click="tab=2;progress=2;"
							>
								<v-icon dark>
									mdi-arrow-right
								</v-icon>							
							</v-btn>
						</template>
						<span>Contact Details</span>
					</v-tooltip>
					<a @click="tab=2;progress=2;"><h3 style="margin-top:15px;">Click here for the last step - Contact details</h3></a>
				</v-row>
				<v-row style="margin-top:30px;">
					<div>
						<v-img
						alt="Vuetify Logo"
						contain
						:src="data.quoteFor.image"
						transition="scale-transition"
						width="140px"
						:style="data.quoteFor.style"
						/>
					</div>
				</v-row>
				<div style="margin-top:30px;background-color:maroon;color:white">
					{{ data.quoteFor.label }}
				</div>
				<v-container>
					<v-row style="margin-top:20px;">
						<h4>Tint</h4>
					</v-row>
					<v-row>
						<v-btn-toggle v-model="data.premium" mandatory>
							<v-btn @click="data.premium=0">
								Regular
							</v-btn>
							<v-btn @click="data.premium=1">
								Premium
							</v-btn>
						</v-btn-toggle>		
					</v-row>
					<v-row style="margin-top:20px;">
						<h4>Removal of existing tint</h4>
					</v-row>
					<v-row>
						<v-btn-toggle v-model="data.tintremoval" mandatory>
							<v-btn @click="data.tintremoval=0">
								Not Required
							</v-btn>
							<v-btn @click="data.tintremoval=1">
								Required
							</v-btn>
						</v-btn-toggle>		
					</v-row>
					<v-row>
						<v-col cols=3>
							<v-text-field
								v-model="data.windows"
								type="number"
								label="Number of windows"
							></v-text-field>
						</v-col>	
						<v-col cols=3>
							<v-text-field
								v-model="data.area"
								type="number"
								label="Total window area in m²"
							></v-text-field>
						</v-col>	
					</v-row>				
					<v-textarea
						v-model="data.comment"
						label="Additional information that will help with a quote"
						style="margin-top:15px;"
					></v-textarea>	
					<v-row style="margin-top:5px">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									class="mx-2"
									fab
									dark
									color="success"
									v-bind="attrs"
									v-on="on"
									@click="tab=2;progress=2;"
								>
									<v-icon dark>
										mdi-arrow-right
									</v-icon>							
								</v-btn>
							</template>
							<span>Contact Details</span>
						</v-tooltip>
					<a @click="tab=2;progress=2;"><h3 style="margin-top:15px;">Click here for the last step - Contact details</h3></a>
					</v-row>
				</v-container>		
			</v-container>
			
			<v-container v-if="isStep(2)">
				<v-row style="margin-top:30px;">
					<div>
						<v-img
						alt="Vuetify Logo"
						contain
						:src="data.quoteFor.image"
						transition="scale-transition"
						width="140px"
						:style="data.quoteFor.style"
						/>
					</div>
				</v-row>
				<div style="margin-top:30px;background-color:maroon;color:white">
					{{ data.quoteFor.label }}
				</div>
				<v-container>
					<v-row style="margin-top:20px;">
						<h4>Your contact details</h4>
					</v-row>
					<v-row>
						<v-col cols=6>
							<v-text-field
								v-model="data.customer.firstName"
								:counter="40"
								:error-messages="errors"
								label="First Name"
								required
								@input="checkValid"
							></v-text-field>
						</v-col>						
						<v-col cols=6>
							<v-text-field
								v-model="data.customer.lastName"
								:counter="40"
								:error-messages="errors"
								label="Last Name"
								required
							></v-text-field>
						</v-col>						
					</v-row>
					<v-row>
						<v-col cols=6>
							<v-text-field
								v-model="data.customer.phoneNumber"
								:counter="11"
								:error-messages="errors"
								label="Phone Number"
								required
								prepend-inner-icon="mdi-phone"
								@input="checkValid"
							></v-text-field>	
						</v-col>						
						<v-col cols=6>
							<v-text-field
								v-model="data.customer.emailAddress"
								:counter="40"
								:error-messages="errors"
								label="Email address"
								required
								prepend-inner-icon="mdi-email"
							></v-text-field>
						</v-col>						
					</v-row>
					<v-row style="margin-top:20px;">
						<h4>Prefered contact method</h4>
					</v-row>
					<v-row>
						<v-btn-toggle v-model="data.customer.preferedcontact" mandatory>
							<v-btn @click="data.customer.preferedcontact=0">
								Phone
							</v-btn>
							<v-btn @click="data.customer.preferedcontact=1">
								Email
							</v-btn>
						</v-btn-toggle>					
					</v-row>
				</v-container>		
				<div style="margin-top:40px;background-color:maroon;color:white">
					Request a quote
				</div>
				<v-container>
					<v-row>
						<div v-if="!requestQuotePressed&&!validData">
							A first name and phone number are required before a quote can be requested.
						</div>
					</v-row>
					<v-row>
						<v-btn
							:disabled="requestQuotePressed||!validData"
							color="success"
							@click="requestQuote"
							style="margin-top:15px;margin-left:15px;"
						>
							Request A Quote
						</v-btn>	
					</v-row>
				</v-container>
			</v-container>
		</v-container>
		
		<v-container v-if="requestQuotePressed">
			<div style="margin-top:40px;background-color:maroon;color:white">
				Thanks
			</div>
			<div>
				Your request for a quote has been received.  We will contact you as soon as we can.
			</div>
			<div>
				We have saved your details and your reference code is: <h2>{{referenceNumber}}</h2>
			</div>
			<div style="margin-top:25px" v-if="data.quoteFor.installtype!=='Automotive'">
				As a price quide our pricing starts from ${{priceGuide}} per m²
			</div>
			<div style="margin-top:25px" v-if="data.quoteFor.installtype==='Automotive'">
				Based on the information provided pricing starts from ${{priceGuide}}
			</div>
			<div style="margin-top:15px">
				*Price guide is not a quote.  We will contact you to give a quote.
			</div>
		</v-container>

	</v-container>
</template>

<script>
	import services from '@/services/services.js'
	
  export default {
    data: () => ({
		comment:"",
		name: 'quickQuote',
		progress:0,
        tab: 0,
		validData:false,
        items: [
			{label:'1. Tinting',step:1},
			{label:'2. More Information',step:2},
			{label:'3. Contact Details',step:3},
			//{label:'4. Price Guide',step:4},
        ],	
		firstRow: {},
		secondRow: [],
		thirdRow: [],	
		fourthRow: [],		
		fifthRow: [],		
		data:{premium:0,tintremoval:0,installlocation:0,sgmlocation:0,customer:{preferedcontact:0,phoneNumber:'',firstName:''}},
		windows:[{label:"front",checked:false},{label:"back",checked:false},{label:"1",checked:false},{label:"2",checked:false},{label:"3",checked:false},{label:">3 Side windows",checked:false},{label:"small section/s",checked:false},{label:"sun roof",checked:false},{label:"other",checked:false}],
		customerRecord:{},
		search:'',
		users:[],
		selected:[],
        headers: [
			{ text: 'Date', align: 'start', value: 'quoteDate' },
			{ text: 'First Name', align: 'start', value: 'firstName' },
			{ text: 'Last Name', value: 'lastName' },
			{ text: 'Email Address', value: 'emailAddress' },
			{ text: 'Phone Number', value: 'phoneNumber' },
			{ text: 'Tinting', value: 'select' }
        ],
		getaquote:[],
		currentItem:[],
		errors:"",
		errorMessage:"",
		fleetCount:[],		
		formOptions:[
			"I love this form",
			"This form is too much"
		],
		installationOptions:[
			"Solartint Penrith",
			"One of our other locations",
			"At your place"
		],
		installAt:'Solartint Penrith',
		formStatus:"I love this form",
		tintItems: [
			"Vehicle",
			"Commercial Vehicle",
			"Fleet",
			"Residential Building",
			"Office",
			"Commercial Building"
		],
		manufacturers: [
				"Audi",
				"BMW",
				"Ford",
				"Holden",
				"Honda",
				"Hyundai",
				"Kia",
				"Mazda",
				"Mitsubishi",
				"Nissan",
				"Toyota",
				"Chrysler",
				"Citroen",
				"Daihatsu",
				"Dodge",
				"Ferrari",
				"Fiat",
				"Infiniti",
				"Isuzu",
				"Jeep",
				"Land Rover",
				"Lexus",
				"Mahindra",
				"Maserati",
				"Mercedes-Benz",
				"MINI",
				"Opel",
				"Peugeot",
				"Proton",
				"Renault",
				"Skoda",
				"Ssangyong",
				"Subaru",
				"Suzuki",
				"Volkswagen",
				"Volvo",
				"Yamaha",
		],
		bodyType: [
			"3 Door Hatch",
			"5 Door Hatch",
			"Coupe",
			"Sedan",
			"Wagon",
			"Ute - Single Cab",
			"Ute - Dual/Extra Cab"
		],
		tintingFeatures: [
			"UV Protection",
			"Heat Reduction",
			"Privacy",
			"Premium",
			"Darkest Legal Tint",
			"Security",
			"Energy Savings",
			"Reduced Glare",
			"Frosting"
		],		
		referenceNumber:'',
		priceGuide:0
    }),
	methods: {
		selection(selection)
		{	
			this.data.quoteFor=selection;
			this.tab=1;
			this.progress=2;
		},
		pad(n) {return n<10 ? "0"+n : n},
		convertUTCDateToLocalDate(date) {
			var newDate = new Date(date);
			return  newDate.toLocaleDateString() + " " + newDate.toLocaleTimeString();   
		},	
		randomItem (items) {
			return items[Math.floor(Math.random()*items.length)];
		},
		selectedChange() {
		},
		selectedItem(item){
			this.currentItem=item;
		},
		addQuote() {
		},
		requestQuote() {
			this.data.installText="Penrith";
			if (this.data.installlocation===2)
				this.data.installText="Mobile";
			this.data.date=Date.now();
			services.saveQuote(this.data).then(response=>{
				if (response.data && response.data.data&&response.data.data.reference)
				{
					this.referenceNumber=response.data.data.reference;
					this.priceGuide=response.data.data.estimate;
					this.$store.state.requestQuotePressed=true;
				}
			}).catch(error=> { console.log(error)});
		},
		isStep(step) {
			return this.tab===step;
		},
		checkValid() {
			if (this.data.customer.phoneNumber && this.data.customer.firstName && this.data.customer.phoneNumber.length>=8&&(this.data.customer.firstName.length>1||this.data.ustomer.lastName.length))
			{
				this.validData=true;
			}
			else
			{
				this.validData=false;
			}
		}
	},	
	computed: {
		vehicleSelected() {
			var result=false;
			if (this.data.quoteFor!=="Residential"&&this.data.quoteFor!=="Commercial")
				result=true;
			return result;
		},
		requestQuotePressed() {
			return this.$store.state.requestQuotePressed;
		},
		accessLevel() {
			return this.$store.state.accessLevel;
		},
		years() {
			var temp=[];
			var now=new Date();
			var last=now;
			for (var i=now.getFullYear()+1;i>=(now.getFullYear()-30);i--)
			{
				temp.push(i);
				last=i;
			}
			temp.push("Older than "+last);

			return temp;
		},
		isVehicle() {
			return this.currentItem.select!==""&&(this.currentItem.select==="Vehicle"||this.currentItem.select==="Commercial Vehicle"||this.currentItem.select==="Fleet")
		},
		isBuilding() {
			return this.currentItem.select!==""&&(this.currentItem.select==="Office"||this.currentItem.select==="Commercial Building"||this.currentItem.select==="Residential Building")
		},
		isCommercial() {
			return this.currentItem.select!==""&&(this.currentItem.select==="Office"||this.currentItem.select==="Commercial Vehicle"||this.currentItem.select==="Fleet"||this.currentItem.select==="Commercial Building")
		},
		isAddressRequired() {
			return this.currentItem.select!==""&&(this.currentItem.select==="Office"||this.currentItem.select==="Commercial Building"||this.currentItem.select==="Fleet"||this.currentItem.select==="Residential Building")
		},
		isFleet() {
			return this.currentItem.select==="Fleet"
		},		
	},
	created() {
		var viewdata={page:"Quote","count":1};
		services.updateStats(viewdata) 
		services.getTintQuoting().then(response=>{
			if (response.data.data)
			{
				var row0=[];
				var row1=[];
				var row2=[];
				var row3=[];
				var row4=[];
				response.data.data.forEach(function(item)
				{			
					if (item.row===0)
						row0.push(item);
					if (item.row===1)
						row1.push(item);
					if (item.row===2)
						row2.push(item);
					if (item.row===3)
						row3.push(item);
					if (item.row===4)
						row4.push(item);
				})
				this.firstRow=row0;
				this.secondRow=row1;
				this.thirdRow=row2;
				this.fourthRow=row3;
				this.fifthRow=row4;
			}
			this.getaquote=response.data.getaquote;this.homeOfficePricing=response.data.homeOffice
		}).catch(error=> { console.log(error)});
		this.fleetCount=[];
		for (var i=1;i<=25;i++)
		{
			this.fleetCount.push(i);
		}
		this.fleetCount.push(">25");	
	},
  }
</script>
