<template>
  <v-app>
	<v-container>
    <v-navigation-drawer
      app
      class="d-none d-md-block"
      color="white"
    >
		<link href="https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css" rel="stylesheet">
		<div>
			<v-container  style="margin-top:25px;">
				<v-row class="md-12">
					<a href="https://solartintpenrith.com.au">
						<v-img
						alt="Solartint"
						contain
						src="/solartint-1.png"
						transition="scale-transition"
						width="250"
						style="margin-top:15px; display:block; margin-left: auto; margin-right: auto;margin-bottom:5px;"
						/>
					</a>
				</v-row>
				<v-row class="md-12" style="margin-left:45px;margin-top:-20px;">
					<a href="https://solartintpenrith.com.au">
						<v-img
						alt="Solartint"
						contain
						src="/solartintPenrith.png"
						transition="scale-transition"
						width="150"
						style="margin-top:15px; display:block; margin-left: auto; margin-right: auto;margin-bottom:5px;"
						/>
					</a>
				</v-row>
				<v-row class="md-12" style="margin-top:15px;margin-left:2px;">
				</v-row>
				<v-row class="md-12" style="margin-left:2px;font-size: 75%;">
					<a href="tel:0450245460">Mobile 0450 245 460</a>
				</v-row>
				<v-row class="md-12" style="margin-left:2px;font-size: 75%;">
					6/37-47 Borec Road, 
				</v-row>
				<v-row class="md-12" style="margin-left:2px;font-size: 75%;">
					Penrith NSW 2750
				</v-row>
				<v-row class="md-12" style="margin-left:2px;font-size: 75%;">
					ABN 56 620 840 717 
				</v-row>
					<div v-if="!admin" style="margin-top:15px;">
						<v-row v-for="item in getRoutes(this.routes)" :key="item.route" class="md-12">
							<v-btn color="primary" style="margin-top:15px;width:100%;" :href=item.route>
								<v-icon left>
									{{item.icon}}
								</v-icon>
								{{item.name}}
							</v-btn>
						</v-row>
					</div>
				</v-container>
		</div>
			<div v-if="admin&&!authenticated" style="margin-top:15px;">
				<v-row v-for="item in getRoutes(this.preAdminRoutes)" :key="item.route" class="md-12">
					<v-btn color="primary" style="margin-top:15px;width:100%;color:white;" :href="item.route">
						<v-icon left>
							{{item.icon}}
						</v-icon>
						{{item.name}}
					</v-btn>
				</v-row>
			</div>
			<div v-if="admin&&authenticated" style="margin-top:15px;">
				<v-row v-for="item in getRoutes(this.adminRoutes)" :key="item.route" class="md-12">
					<v-btn color="primary" style="margin-top:15px;width:100%;color:white;" :href="item.route">
						<v-icon left>
							{{item.icon}}
						</v-icon>
						{{item.name}}
					</v-btn>
				</v-row>
			</div>
	</v-navigation-drawer>
		<v-container class="d-lg-none">
			<div>
				<v-row class="md-12" style="margin-bottom: 15px;">
					<a href="https://solartintpenrith.com.au">
						<v-img
						alt="Solartint"
						contain
						src="/solartint-1.png"
						transition="scale-transition"
						width="200"
						style="margin-top:15px; display:block; margin-left: auto; margin-right: auto;margin-bottom:5px;"
						/>
					</a>
					<a href="https://solartintpenrith.com.au" style="margin-top:37px; margin-left:10px;">
						<v-img
						alt="Solartint"
						contain
						src="/solartintPenrith.png"
						transition="scale-transition"
						width="150"
						style="margin-top:15px; display:block; margin-left: auto; margin-right: auto;margin-bottom:5px;"
						/>
					</a>
				</v-row>

				<div v-if="!admin">
					<v-row v-for="item in getRoutes(routes)" :key="item.route" class="md-12">
						<v-btn color="primary" style="margin-top:5px;width:100%;" :href="item.route">
							<v-icon left>
								{{item.icon}}
							</v-icon>
							{{item.name}}
						</v-btn>
					</v-row>
				</div>
			</div>
			<div v-if="admin&&!authenticated" >
				<v-row v-for="item in getRoutes(this.preAdminRoutes)" :key="item.route" class="md-12">
					<v-btn color="primary" style="margin-top:5px;width:100%;color:white;" :href="item.route">
							<v-icon left>
								{{item.icon}}
							</v-icon>
						{{item.name}}
					</v-btn>
				</v-row>
			</div>
			<div v-if="admin&&authenticated" >
				<v-row v-for="item in getRoutes(this.adminRoutes)" :key="item.route" class="md-12">
					<v-btn color="primary" style="margin-top:5px;width:100%;color:white;" :href="item.route">
							<v-icon left>
								{{item.icon}}
							</v-icon>
						{{item.name}}
					</v-btn>
				</v-row>
			</div>
		</v-container>
	</v-container>
	
    <v-main>
          <router-view/>
    </v-main>
	<v-footer
	v-if="!admin"
	dark
	padless
	inset app
	>
		<a href="tel:0247222833" style="text-decoration:none !important;margin-left:5px;">					
			<v-icon style="margin-left: 2px;">
				mdi-phone
			</v-icon>
		</a>			
		<a href="mailto:garagemethodsales@outlook.com" style="text-decoration:none !important;margin-left:5px;">					
			<v-img
			alt="Email solartint penrith"
			contain
			src="/email.svg"
			transition="scale-transition"
			width="18"
			style="margin-left: 2px;background-color:white;"
			/>
		</a>			
		<a href="https://www.facebook.com/solartintpenrithwindsor/" style="text-decoration:none !important;margin-left:5px;">
			<v-img
			alt="Solartint penrith facebook page"
			contain
			src="/facebook.svg"
			transition="scale-transition"
			width="18"
			style="margin-left: 2px;"
			/>				
		</a>	
		<v-card
		class="flex"
		flat
		tile
		>
		<v-card-text class="py-2 white--text text-center">
		<a href="https://sgmcars.com.au" style="text-decoration:none !important;color:white;">
		&copy; {{ new Date().getFullYear() }} — <strong>Solartint Penrith</strong>
		</a>
		</v-card-text>
		</v-card>
	</v-footer>	
	<v-footer
	v-if="admin"
	padless
	inset app
	>
		<v-card
		class="flex"
		flat
		tile
		style="background-color:maroon;"
		>
		<v-card-text class="py-2 white--text text-center">
		<a href="https://solartintpenrith.com.au" style="width:100%;text-decoration:none !important;color:white;background-color:maroon;">
		<strong>Admin - Solartint Penrith</strong>
		</a>
		</v-card-text>
		</v-card>
	</v-footer>	
  </v-app>
</template>

<script>

export default {
  name: 'App',
    watch: {
      '$route' (to, from) {
        document.title = to.meta.title || 'Your Website'
		console.log(from);
      }
    },
  data: () => ({
		routes:[
			{route:'/about',name:'About',icon:'mdi-information',accessLevel:0},
			{route:'/automotive',name:'Automotive',icon:'mdi-car',accessLevel:0},
			{route:'/residential',name:'Residential',icon:'mdi-home',accessLevel:0},
			{route:'/commercial',name:'Commercial',icon:'mdi-home-city',accessLevel:0},
			{route:'/quote',name:'Get a Quote',icon:'mdi-currency-usd',accessLevel:0},
			{route:'/sgm/solartintpenrith/admin/logout',name:'Log Out',icon:'mdi-logout',accessLevel:1},
			{route:'/sgm/solartintpenrith/admin/schedule',name:'Admin',icon:'mdi-cogs',accessLevel:1},
		],
		adminRoutes:[
			{route:'/about',name:'Solartint Penrith',icon:'mdi-web',accessLevel:0},
			{route:'/sgm/solartintpenrith/admin/schedule',name:'Schedule',icon:'mdi-calendar',accessLevel:1},
			{route:'/sgm/solartintpenrith/admin/quote',name:'Quotes',icon:'mdi-phone',accessLevel:1},
			{route:'/sgm/solartintpenrith/admin/access',name:'Users',icon:'mdi-account-multiple',accessLevel:2},
			{route:'/sgm/solartintpenrith/admin/inventory',name:'Inventory',icon:'mdi-film',accessLevel:2},
			{route:'/sgm/solartintpenrith/admin/stats',name:'Website Statistics',icon:'mdi-web',accessLevel:2},
			{route:'/sgm/solartintpenrith/admin/quoteconfig',name:'Quote Settings',icon:'mdi-cash-usd',accessLevel:2},
			{route:'/sgm/solartintpenrith/admin/password',name:'Change Password',icon:'mdi-lock',accessLevel:1},
			{route:'/sgm/solartintpenrith/admin/logout',name:'Log Out',icon:'mdi-logout',accessLevel:1},
		],
		preAdminRoutes:[
			{route:'/about',name:'Solartint Penrith',accessLevel:0},
		],
		access:1
	}),
  computed: {
	admin() {
		return this.$store.state.admin;
	},
	accessLevel() {
		return this.$store.state.accessLevel;
	},
	authenticated() {
		return this.$store.state.authenticated;
	},
	showSolartint() {
		return this.$store.state.showSolartint;
	}	
  },
	methods: {
		logout() {
			this.$store.commit('logout');					
		},
		getRoutes(routesArray)
		{
			var result=[];
//			console.log(this.accessLevel);
//			console.log(this.$store.state.accessLevel);
			routesArray.forEach(item =>{
				if (this.accessLevel>=item.accessLevel)
				{
					result.push(item);
				}
			});
			return result;
		}
	},	
};
</script>
