import Vue from 'vue'
import VueRouter from 'vue-router'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import about from '../views/about.vue'
import automotive from '../views/automotive.vue'
import residential from '../views/residential.vue'
import commercial from '../views/commercial.vue'
import quote from '../views/quickquote.vue'
import adminSchedule from '../views/admin/schedule.vue'
import adminGetQuote from '../views/admin/getquote.vue'
import adminLogin from '../views/admin/login.vue'
import adminUsers from '../views/admin/users.vue'
import adminPassword from '../views/admin/password.vue'
import adminStats from '../views/admin/stats.vue'
import adminQuoteConfig from '../views/admin/quoteconfig.vue'
import adminInventory from '../views/admin/inventory.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

const routes = [
	{
		path: '/about',
		name: 'Solartint Penrith',
		component: about
	},
	{
		path: '/automotive',
		name: 'Automotive',
		component: automotive
	},
	{
		path: '/residential',
		name: 'Residential',
		component: residential
	}, 
	{
		path: '/commercial',
		name: 'Commercial',
		component: commercial
	},
	{
		path: '/quote',
		name: 'Get a quote',
		component: quote
	},
	{
		path: '/sgm/solartintpenrith/admin/quote',
		name: 'adminGetQuote',
		component: adminGetQuote
	},	
	{
		path: '/sgm/solartintpenrith/admin/schedule',
		name: 'adminSchedule',
		component: adminSchedule
	},	
	{
		path: '/sgm/solartintpenrith/admin/inventory',
		name: 'adminInventory',
		component: adminInventory
	},		
	{
		path: '/sgm/solartintpenrith/admin/login',
		name: 'Login',
		component: adminLogin
	},
	{
		path: '/sgm/solartintpenrith/admin/logout',
		name: 'Logout',
		component: adminLogin
	},
	{
		path: '/gareth',
		name: 'LoginGareth',
		component: adminLogin
	},
	{
		path: '/sgm/solartintpenrith/admin/access',
		name: 'Users',
		component: adminUsers
	},
	{
		path: '/sgm/solartintpenrith/admin/password',
		name: 'Password change',
		component: adminPassword
	},
	{
		path: '/sgm/solartintpenrith/admin/stats',
		name: 'Website Statistics',
		component: adminStats
	},
	{
		path: '/sgm/solartintpenrith/admin/quoteconfig',
		name: 'Quote Settings',
		component: adminQuoteConfig
	},
	{
		path: '*',
		redirect: '/about'
	}  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	//console.log(store)
	//console.log(to);
	if (to.path=="/residential"||to.path=="/commercial"||to.path=="/quote"||to.path=="/locations"||to.path=="/wheels")
	{
		store.state.showSolartint=false;
	}
	else
	{
		store.state.showSolartint=true;
	}
	if (to.path.indexOf("/sgm/solartintpenrith/admin/logout")>=0)
	{
		//console.log("LOGGING OUT");
		store.commit('setAccessLevel',0);					
		store.commit('logout');			
		next();
	}	
	else
	{
		if (to.path.indexOf("/admin/")>0&&to.path.indexOf("/sgm/solartintpenrith/admin/login")<0&&to.path.indexOf("/sgm/solartintpenrith/admin/logout")<0)
		{
			store.state.admin=true
			
			if (store.state.token==="")
			{
				store.commit('setAccessLevel',0);					
				store.commit('logout');			
				window.location.href = "/about";
			}
			//console.log("admin route "+to.path);
			next();
		}
		else
		{
			store.state.admin=false
			//console.log("NOT admin route "+to.path);
			next();
			/*if (!this.$store.state.admin&&to.name.path.indexOf("/admin/")>0)
			{
				next({name:"Solartint Penrith"});
				console.log("admin path not authorised");
			}
			else
			{
				next();
			}*/
		}
	}
})

export default router
