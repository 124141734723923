<template>
	<div>
<v-card>
	<div style='background-color:#800000;color:white;margin-top:20px'>
		<v-row>
			<v-col cols=2>
				Status: {{quoteDetails.status}}    
			</v-col>	
			<v-col cols=2>
				Scheduled: {{quoteDetails.scheduledDate}}
				<b v-if="weekend">****WEEKEND</b>
			</v-col>	
			<v-col cols=4>
				Customer: {{quoteDetails.customersummary}}
			</v-col>	
			<v-col cols=2>
				{{quoteDetails.quoteFor.label}}
			</v-col>				
			<v-col cols=2>
				{{quoteDetails.installText}}
			</v-col>				
		</v-row>
	</div>
    <v-tabs background-color="#800000" dark>
      <v-tab >
        <v-icon left>
          mdi-account
        </v-icon>
        Customer
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-camera
        </v-icon>
        Photos
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-chart-tree
        </v-icon>
        Options
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-currency-usd
        </v-icon>
        Quote
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-cogs
        </v-icon>
        Installation
      </v-tab>
	<v-tab>
		<v-icon left>
			mdi-clock
		</v-icon>
		Schedule
	</v-tab>	  
	<v-tab>
		<v-icon left>
			mdi-receipt
		</v-icon>
		Invoice
	</v-tab>	  

      <v-tab-item>
		<v-container>
			<customerDetails  v-if="quoteDetails.customerid" :customerDetails="customerDetails"></customerDetails>
		</v-container>
      </v-tab-item>
      <v-tab-item>
		<template>
			<div style='background-color:red;color:white;margin-top:20px' v-if="uploadError">
				{{uploadError}}
			</div>
			<div style='background-color:green;color:white;margin-top:20px' v-if="uploadSuccess">
				{{uploadSuccess}}
			</div>
			<v-file-input
				v-model="beforeFile"
				label="Add before Photo"
				filled
				prepend-icon="mdi-camera"
				style="margin-top:15px;"
				@change="uploadImageBefore"
				multiple
			></v-file-input>			
			<v-file-input
				v-model="afterFile"
				label="Add after Photo"
				filled
				prepend-icon="mdi-camera"
				@change="uploadImageAfter"
				multiple
			></v-file-input>
			<div style="background-color:maroon;color:white;margin-bottom:5px;">
				Before
			</div>
			<v-row style="margin-left:10px;margin-bottom:10px;" v-if="quoteDetails.photos&&quoteDetails.photos.before">
				<v-col
				v-for="(n,index) in quoteDetails.photos.before"
				:key="index"
				class="d-flex child-flex"
				cols="4"
				>
					<v-row style="margin-top:10px;">
						<a :href="'https://test.iotx3.com/tint/download?name='+n.filename" target="_blank">
							<img :src="'https://test.iotx3.com/tint/download?name='+n.filename" :alt="n.filename" style="width:90%">
						</a>
						<div>
							Delete <v-icon v-if="accessLevel>=2" style="margin-right:10px;" @click="deleteImage(n,true)">mdi-delete</v-icon>
							Move to after <v-icon v-if="accessLevel>=1" label="Move image after" @click="moveAfter(n)">mdi-arrow-down</v-icon>
						</div>
					</v-row>
				</v-col>
			</v-row>			
			<div style="background-color:maroon;color:white; margin-top:15px; margin-bottom:5px;">
				After
			</div>
			<v-row style="margin-left:10px;margin-bottom:10px;"  v-if="quoteDetails.photos&&quoteDetails.photos.after">
				<v-col
				v-for="(n,index) in quoteDetails.photos.after"
				:key="index"
				class="d-flex child-flex"
				cols="4"
				>
					<v-row style="margin-top:10px;">
						<a :href="'https://test.iotx3.com/tint/download?name='+n.filename" target="_blank">
							<img :src="'https://test.iotx3.com/tint/download?name='+n.filename" :alt="n.filename" style="width:90%">
						</a>
						<div>
							Delete <v-icon v-if="accessLevel>=2" style="margin-right:10px;" @click="deleteImage(n,true)">mdi-delete</v-icon>
							Move to before <v-icon v-if="accessLevel>=1" label="Move image before" @click="moveBefore(n)">mdi-arrow-up</v-icon>
						</div>
					</v-row>
				</v-col>
			</v-row>			
		</template>
      </v-tab-item>
      <v-tab-item>
		<v-row style="margin-top:5px">
			<v-col cols=2>
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-2"
							fab
							dark
							color="indigo"
							v-bind="attrs"
							v-on="on"
							@click="saveData"
							style="margin-top:5px"
						>
							<v-icon dark>
								mdi-content-save
							</v-icon>
						</v-btn>
					</template>
					<span>Save changes</span>
				</v-tooltip>
			</v-col>
			<v-col cols=2>
				<div>
					<a href="#" @click="quoteForDialog = true">
						<v-img
						alt="Vuetify Logo"
						contain
						:src="quoteDetails.quoteFor.image"
						transition="scale-transition"
						width="140px"
						/>
					</a>
				</div>
			</v-col>
			<v-col cols=6>
				<div style="margin-left:20px;">
					<b>{{quoteDetails.quoteFor.label}}</b>
					<div v-if="quoteDetails.priceGuide">
						Website generated price guide displayed ${{quoteDetails.priceGuide}} <em v-if="quoteDetails.guideOn!=quoteDetails.quoteFor.label">(Originally {{quoteDetails.guideOn}})</em>
					</div>
					<div v-if="quoteDetails.priceQuoted">
						Quoted price ${{quoteDetails.priceQuoted}} <div v-if="quoteDetails.quotedOn!=quoteDetails.quoteFor.label">(Originally {{quoteDetails.quotedOn}})</div>
					</div>
					<div v-if="quoteDetails.quoteAmount">
						Website generated price guide displayed ${{quoteDetails.priceGuide}} <em v-if="quoteDetails.guideOn!=quoteDetails.quoteFor.label">(Originally {{quoteDetails.guideOn}})</em>
					</div>
					<div v-if="quoteDetails.quoteAmount">
						Quoted price ${{quoteDetails.priceQuoted}} <div v-if="quoteDetails.quotedOn!=quoteDetails.quoteFor.label">(Originally {{quoteDetails.quotedOn}})</div>
					</div>
					
				</div>
			</v-col>
		</v-row>
		<div v-if="quoteDetails.quoteFor.installtype==='Automotive'">
			<v-container>
				<v-row>
					<v-col col=6>
						<v-row>
								<h4>Tint</h4>
						</v-row>
						<v-row>
							<v-btn-toggle v-model="quoteDetails.premium" mandatory>
								<v-btn @click="quoteDetails.premium=0">
									Regular
								</v-btn>
								<v-btn @click="quoteDetails.premium=1">
									Premium
								</v-btn>
							</v-btn-toggle>						
						</v-row>
					</v-col>
					<v-col col=6>
						<v-row>
								<h4>Removal of existing tint</h4>
						</v-row>
						<v-row>
							<v-btn-toggle v-model="quoteDetails.tintremoval" mandatory>
								<v-btn @click="quoteDetails.tintremoval=0">
									Not Required
								</v-btn>
								<v-btn @click="quoteDetails.tintremoval=1">
									Required
								</v-btn>
							</v-btn-toggle>					
						</v-row>
					</v-col>
				</v-row>
				<v-row style="margin-top:20px;">
					<v-col col=6>
						<v-row>
								<h4>Install location</h4>
						</v-row>
						<v-row>
							<v-btn-toggle v-model="quoteDetails.installlocation" mandatory>
								<v-btn @click="quoteDetails.installlocation=0">
									Solartint Penrith
								</v-btn>
								<v-btn @click="quoteDetails.installlocation=2">
									My Place
								</v-btn>
							</v-btn-toggle>						
						</v-row>
					</v-col>
				</v-row>
				<div style="margin-top:15px">
					<v-row>
						<v-col cols=8>
							<v-combobox
								v-model="quoteDetails.manufacturer"
								:counter="100"
								:items="manufacturers"
								label="Manufacturer"
								required
								prepend-inner-icon="mdi-car"
							></v-combobox>
						</v-col>						
						<v-col cols=4>
							<v-text-field
								v-model="quoteDetails.registration"
								:counter="10"
								label="Rego"
								required
							></v-text-field>			
						</v-col>						
					</v-row>
					<v-row>
						<v-col cols=8>
							<v-text-field
								v-model="quoteDetails.model"
								:counter="100"
								label="Model"
								required
							></v-text-field>			
						</v-col>						
						<v-col cols=4>
							<v-select
								v-model="quoteDetails.year"
								:items="years"
								label="Make Year"
								data-vv-name="year"
								required
							></v-select>	
						</v-col>						
					</v-row>
				</div>
				<div>
					<v-textarea
						v-model="quoteDetails.comment"
						label="Additional information that will help with a quote"
					></v-textarea>	
				</div>
			</v-container>
		</div>
		<div v-if="quoteDetails.quoteFor.installtype!=='Automotive'">
			<v-container>
				<v-row>
					<v-col cols=6  style="padding:0px">
						<h4>Tint</h4>
						<v-btn-toggle v-model="quoteDetails.premium" mandatory>
							<v-btn>
								Regular
							</v-btn>
							<v-btn>
								Premium
							</v-btn>
						</v-btn-toggle>		
					</v-col>
					<v-col cols=6  style="padding-top:0px">
						<h4>Removal of existing tint</h4>
						<v-btn-toggle v-model="quoteDetails.tintremoval" mandatory>
							<v-btn>
								Not Required
							</v-btn>
							<v-btn>
								Required
							</v-btn>
						</v-btn-toggle>		
					</v-col>
				</v-row>
				<v-row>
					<v-col cols=5>
						<v-text-field
							v-model="quoteDetails.windows"
							type="number"
							label="Number of windows"
						></v-text-field>
					</v-col>	
					<v-col cols=5>
						<v-text-field
							v-model="quoteDetails.area"
							type="number"
							label="Total window area in m²"
						></v-text-field>
					</v-col>	
				</v-row>				
				<v-textarea
					v-model="quoteDetails.comment"
					label="Additional information that will help with a quote"
					style="margin-top:15px;"
				></v-textarea>			
			</v-container>
		</div>
      </v-tab-item>
		<v-tab-item>
			<v-row style="margin-top:5px">
				<v-col cols=2>
					<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								class="mx-2"
								fab
								dark
								color="indigo"
								v-bind="attrs"
								v-on="on"
								@click="saveData"
								style="margin-top:5px"
							>
								<v-icon dark>
									mdi-content-save
								</v-icon>
							</v-btn>
						</template>
						<span>Save changes</span>
					</v-tooltip>
				</v-col>
				<v-col cols=2>
					<div>
						<a href="#" @click="quoteForDialog = true">
							<v-img
							alt="Vuetify Logo"
							contain
							:src="quoteDetails.quoteFor.image"
							transition="scale-transition"
							width="140px"
							/>
						</a>
					</div>
				</v-col>
				<v-col cols=6>
					<div style="margin-left:20px;margin-right:10px;">
						<b>{{quoteDetails.quoteFor.label}}</b>
						<div v-if="quoteDetails.priceGuide">
							Website generated price guide displayed ${{quoteDetails.priceGuide}} <em v-if="quoteDetails.guideOn!=quoteDetails.quoteFor.label">(Originally {{quoteDetails.guideOn}})</em>
						</div>
						<div v-if="quoteDetails.priceQuoted">
							Quoted price ${{quoteDetails.priceQuoted}} <div v-if="quoteDetails.quotedOn!=quoteDetails.quoteFor.label">(Originally {{quoteDetails.quotedOn}})</div>
						</div>
						<div v-if="quoteDetails.quoteAmount">
							Website generated price guide displayed ${{quoteDetails.priceGuide}} <em v-if="quoteDetails.guideOn!=quoteDetails.quoteFor.label">(Originally {{quoteDetails.guideOn}})</em>
						</div>
						<div v-if="quoteDetails.quoteAmount">
							Quoted price ${{quoteDetails.priceQuoted}} <div v-if="quoteDetails.quotedOn!=quoteDetails.quoteFor.label">(Originally {{quoteDetails.quotedOn}})</div>
						</div>
						<div v-if="quoteDetails.premium===0">
							Regular tint
						</div>
						<div v-if="quoteDetails.premium===1">
							<b>Premium Tint</b>
						</div>
						<div v-if="quoteDetails.tintremoval===0">
							Tint removal not required
						</div>
						<div v-if="quoteDetails.tintremoval===1">
							<b>Tint removal required</b>
						</div>
						<div v-if="quoteDetails.installlocation===0&&quoteDetails.quoteFor.installtype==='Automotive'">
							Install at Solartint Penrith
						</div>
						<div v-if="quoteDetails.installlocation===2&&quoteDetails.quoteFor.installtype==='Automotive'">
							<b>Customer location installation</b>
						</div>
					</div>
				</v-col>
			</v-row>
			<div v-if="quoteDetails.quoteFor.installtype!=='Automotive'">
				<v-row style="margin-left:10px;margin-right:10px;">
					<v-col cols=3>
						<v-text-field 
							v-model="quoteDetails.area"
							type="number" 
							label="Total window area in m²"
						/>
					</v-col>
					<v-col cols=3>
						<v-text-field 
							v-model="quoteDetails.pricepermetre"
							type="number" 
							label="Price per m²"
						/>
					</v-col>
					<v-col cols=6 style="margin-top:20px; text-align:right;">
						${{quoteDetails.area*quoteDetails.pricepermetre}}
					</v-col>
				</v-row>
			</div>
			<div  v-for="(item,index) in quoteDetails.finalQuote" v-bind:key="index" >
				<v-row v-if="quoteDetails.tintremoval===1" style="margin-left:10px;">
					<v-col cols=3>
						<v-text-field 
							v-model="quoteDetails.tintremovalarea"
							type="number" 
							label="Total window area in m²"
						/>
					</v-col>
					<v-col cols=3>
						<v-text-field 
							v-model="quoteDetails.tintremovalPrice"
							type="number" 
							label="Price per m²"
						/>
					</v-col>
					<v-col v-if="quoteDetails.tintremovalarea" cols=6 style="margin-top:20px; text-align:right;">
						${{quoteDetails.tintremovalarea*quoteDetails.tintremovalPrice}}
					</v-col>
				</v-row>
			</div>
			<div v-if="quoteDetails.quoteItems&&quoteDetails.quoteItems.length>0" style="margin-left:20px;margin-bottom:0px;">
				<v-row>
					<v-col cols=4>
						<b>Description</b>
					</v-col>
					<v-col cols=1>
						<b>Quantity</b>
					</v-col>
					<v-col cols=2>
						<b>Amount</b>
					</v-col>
					<v-col cols=3>
						<b>Total</b>
					</v-col>
					<v-col cols=2>
						<b>Actions</b>
					</v-col>
				</v-row>
			</div>			
			<div v-if="quoteDetails.quoteItems" :key="refreshQuoteItems" >
				<div v-for="(item,index) in quoteDetails.quoteItems" v-bind:key="index" style="margin-left:20px;margin-bottom:5px;">
					<v-row>
						<v-col cols=4>
							{{item.description}} 
						</v-col>
						<v-col v-if="item.quantity" cols=1>
							{{item.quantity}} 
						</v-col>
						<v-col v-if="item.price" cols=2>
							${{item.price}} 
						</v-col>
						<v-col v-if="item.amount" cols=3>
							${{item.amount}}
						</v-col>
						<v-col cols=2>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										class="mx-2"
										dark
										height="25px"
										color="indigo"
										v-bind="attrs"
										v-on="on"
										@click="editItem(index)"
									>
										<v-icon dark>
											mdi-pencil
										</v-icon>
									</v-btn>
								</template>
								<span>Edit</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										class="mx-2"
										dark
										height="25px"
										color="indigo"
										v-bind="attrs"
										v-on="on"
										@click="deleteItem(index)"
									>
										<v-icon dark>
											mdi-delete
										</v-icon>
									</v-btn>
								</template>
								<span>Delete</span>
							</v-tooltip>
						</v-col>
					</v-row>
				</div>
			</div>
			<div>
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-2"
							fab
							dark
							color="indigo"
							v-bind="attrs"
							v-on="on"
							@click="addQuote"
							style="margin-top:5px"
						>
							<v-icon dark>
								mdi-plus
							</v-icon>
						</v-btn>
					</template>
					<span>Add new quote line</span>
				</v-tooltip>
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-2"
							dark
							color="orange"
							v-bind="attrs"
							v-on="on"
							@click="setPriceGuide"
							style="margin-top:5px"
						>
							Price Guide
						</v-btn>
					</template>
					<span>Set quoted</span>
				</v-tooltip>
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-2"
							dark
							color="indigo"
							v-bind="attrs"
							v-on="on"
							@click="setQuoted"
							style="margin-top:5px"
						>
							Quoted
						</v-btn>
					</template>
					<span>Set quoted</span>
				</v-tooltip>
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-2"
							dark
							color="error"
							v-bind="attrs"
							v-on="on"
							@click="setCancel"
							style="margin-top:5px"
						>
							Cancelled
						</v-btn>
					</template>
					<span>Set Cancelled</span>
				</v-tooltip>
			</div>
		</v-tab-item>
		<v-tab-item>
			<v-row style="margin-top:5px">
				<v-col cols=2>
					<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								class="mx-2"
								fab
								dark
								color="indigo"
								v-bind="attrs"
								v-on="on"
								@click="saveData"
								style="margin-top:5px"
							>
								<v-icon dark>
									mdi-content-save
								</v-icon>
							</v-btn>
						</template>
						<span>Save changes</span>
					</v-tooltip>
				</v-col>
				<v-col cols=2>
					<div>
						<a href="#" @click="quoteForDialog = true">
							<v-img
							alt="Vuetify Logo"
							contain
							:src="quoteDetails.quoteFor.image"
							transition="scale-transition"
							width="140px"
							/>
						</a>
					</div>
				</v-col>
				<v-col cols=6>
					<div style="margin-left:20px;margin-right:10px;">
						<b>{{quoteDetails.quoteFor.label}}</b>
						<div v-if="quoteDetails.priceGuide">
							Website generated price guide displayed ${{quoteDetails.priceGuide}} <em v-if="quoteDetails.guideOn!=quoteDetails.quoteFor.label">(Originally {{quoteDetails.guideOn}})</em>
						</div>
						<div v-if="quoteDetails.priceQuoted">
							Quoted price ${{quoteDetails.priceQuoted}} <div v-if="quoteDetails.quotedOn!=quoteDetails.quoteFor.label">(Originally {{quoteDetails.quotedOn}})</div>
						</div>
						<div v-if="quoteDetails.quoteAmount">
							Website generated price guide displayed ${{quoteDetails.priceGuide}} <em v-if="quoteDetails.guideOn!=quoteDetails.quoteFor.label">(Originally {{quoteDetails.guideOn}})</em>
						</div>
						<div v-if="quoteDetails.quoteAmount">
							Quoted price ${{quoteDetails.priceQuoted}} <div v-if="quoteDetails.quotedOn!=quoteDetails.quoteFor.label">(Originally {{quoteDetails.quotedOn}})</div>
						</div>
						<div v-if="quoteDetails.premium===0">
							Regular tint
						</div>
						<div v-if="quoteDetails.premium===1">
							<b>Premium Tint</b>
						</div>
						<div v-if="quoteDetails.tintremoval===0">
							Tint removal not required
						</div>
						<div v-if="quoteDetails.tintremoval===1">
							<b>Tint removal required</b>
						</div>
						<div v-if="quoteDetails.installlocation===0&&quoteDetails.quoteFor.installtype==='Automotive'">
							Install at Solartint Penrith
						</div>
						<div v-if="quoteDetails.installlocation===2&&quoteDetails.quoteFor.installtype==='Automotive'">
							<b>Customer location installation</b>
						</div>
					</div>
				</v-col>
			</v-row>
			<div v-if="quoteDetails.quoteFor.installtype!=='Automotive'">
				<v-row style="margin-left:10px;margin-right:10px;">
					<v-col cols=3>
						<v-text-field 
							v-model="quoteDetails.area"
							type="number" 
							label="Total window area in m²"
						/>
					</v-col>
					<v-col cols=3>
						<v-text-field 
							v-model="quoteDetails.pricepermetre"
							type="number" 
							label="Price per m²"
						/>
					</v-col>
					<v-col cols=6 style="margin-top:20px; text-align:right;">
						${{quoteDetails.area*quoteDetails.pricepermetre}}
					</v-col>
				</v-row>
			</div>
			<div  v-for="(item,index) in quoteDetails.finalQuote" v-bind:key="index" >
				<v-row v-if="quoteDetails.tintremoval===1" style="margin-left:10px;">
					<v-col cols=3>
						<v-text-field 
							v-model="quoteDetails.tintremovalarea"
							type="number" 
							label="Total window area in m²"
						/>
					</v-col>
					<v-col cols=3>
						<v-text-field 
							v-model="quoteDetails.tintremovalPrice"
							type="number" 
							label="Price per m²"
						/>
					</v-col>
					<v-col v-if="quoteDetails.tintremovalarea" cols=6 style="margin-top:20px; text-align:right;">
						${{quoteDetails.tintremovalarea*quoteDetails.tintremovalPrice}}
					</v-col>
				</v-row>
			</div>
			<div v-if="quoteDetails.installItems&&quoteDetails.installItems.length>0" style="margin-left:20px;margin-bottom:0px;">
				<v-row>
					<v-col cols=4>
						<b>Installation</b>
					</v-col>
					<v-col cols=6>
						<b>Description</b>
					</v-col>					
					<v-col cols=2>
						<b>Actions</b>
					</v-col>
				</v-row>
			</div>			
			<div v-if="quoteDetails.quoteItems" :key="refreshQuoteItems"  style="margin-left:20px;margin-bottom:5px;">
				<div v-for="(item,index) in quoteDetails.installItems" v-bind:key="index">
					<v-row style="margin-left:0px;margin-bottom:5px;">
						<v-col cols=4>
							<v-row>
								<span v-for="option in item.option" v-bind:key="option">
									{{option}}&nbsp;
								</span>
							</v-row>
						</v-col>
						<v-col v-if="item.description" cols=6>
							{{item.description}} 
						</v-col>
						<v-col cols=2>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										class="mx-2"
										dark
										height="25px"
										color="indigo"
										v-bind="attrs"
										v-on="on"
										@click="editInstallItem(index)"
									>
										<v-icon dark>
											mdi-pencil
										</v-icon>
									</v-btn>
								</template>
								<span>Edit</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										class="mx-2"
										dark
										height="25px"
										color="indigo"
										v-bind="attrs"
										v-on="on"
										@click="deleteInstallItem(index)"
									>
										<v-icon dark>
											mdi-delete
										</v-icon>
									</v-btn>
								</template>
								<span>Delete</span>
							</v-tooltip>
						</v-col>
					</v-row>
				</div>
			</div>
			<div>
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-2"
							fab
							dark
							color="indigo"
							v-bind="attrs"
							v-on="on"
							@click="addInstall"
							style="margin-top:5px"
						>
							<v-icon dark>
								mdi-plus
							</v-icon>
						</v-btn>
					</template>
					<span>Add installation line</span>
				</v-tooltip>
			</div>
		</v-tab-item>
		<v-tab-item>
			<v-row style="margin-top:5px;margin-bottom:-7px;">
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-2"
							dark
							color="orange"
							v-bind="attrs"
							v-on="on"
							@click="setPriceGuide"
							style="margin-top:5px"
						>
							Price Guide
						</v-btn>
					</template>
					<span>Set quoted</span>
				</v-tooltip>
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-2"
							dark
							color="indigo"
							v-bind="attrs"
							v-on="on"
							@click="showScheduleDialog"
							style="margin-top:5px"
						>
							Schedule
						</v-btn>
					</template>
					<span>Schedule this quote</span>
				</v-tooltip>
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-2"
							dark
							color="error"
							v-bind="attrs"
							v-on="on"
							@click="setCancel"
							style="margin-top:5px"
						>
							Cancelled
						</v-btn>
					</template>
					<span>Set Cancelled</span>
				</v-tooltip>
			</v-row>
			<scheduleDetails @selectedDate="dateRangeChange" :eventSettings="eventSettings"></scheduleDetails>
		</v-tab-item>
		<v-tab-item>
			<v-row style="margin-top:2px">
				<v-col
					cols="12"
					sm="6"
					md="4"
					style="margin-left:15px"
				>
					<v-menu
						v-model="menu2"
						:close-on-content-click="false"
						:nudge-right="40"
						transition="scale-transition"
						offset-y
						min-width="auto"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="date"
								label="Date"
								prepend-icon="mdi-calendar"
								readonly
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="date"
							@input="menu2 = false"
						>
						</v-date-picker>
					</v-menu>
				</v-col>
				<v-col>
					<v-switch v-if="stInvoice"
						v-model="stInvoice"
						label="Invoice"
						style="margin-left:15px"
					></v-switch>	
					<v-switch v-if="!stInvoice"
						v-model="stInvoice"
						label="Receipt"
						style="margin-left:15px"
					></v-switch>	
				</v-col>
				<v-col v-if="stInvoice">
					<v-row>
						<v-checkbox v-model="creditPayment" label="Credit Card" style="margin-right:10px;" @click="checkPayment"></v-checkbox>
						<v-checkbox v-model="cashPayment" label="Cash" @click="checkPayment"></v-checkbox>
					</v-row>
				</v-col>	
			</v-row>
			<v-row>
				<v-col 					c
					ols="12"
					sm="6"
					md="4"
					style="margin-left:15px"
				>
					<v-combobox
						v-model="installer"
						:items="['Ben', 'Sumin', 'Installer 1', 'Installer 2', 'Installer 3', 'Installer 4', 'Installer 5']"
						label="Installed by"
					></v-combobox>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-switch v-if="useQuoteAmount&&stInvoice"
						v-model="useQuoteAmount"
						label="Use Quote Details"
						style="margin-left:15px"
					></v-switch>	
					<v-switch v-if="!useQuoteAmount||!stInvoice"
						v-model="useQuoteAmount"
						label="Manually Set"
						style="margin-left:15px"
					></v-switch>	
				</v-col>					
				<v-col v-if="!useQuoteAmount||!stInvoice" cols=3>
					<v-text-field 
						v-if="stInvoice"
						v-model="invoiceTotal"
						prepend-icon="mdi-currency-usd"
						type="number" 
						step=100
						min=99
						label="Invoice total (including GST)"
					/>
					<v-text-field 
						v-if="!stInvoice"
						v-model="invoiceTotal"
						prepend-icon="mdi-currency-usd"
						type="number" 
						step=100
						min=99
						label="Invoice total"
					/>
				</v-col>
				<v-col v-if="useQuoteAmount&&stInvoice" cols=3>
					<div
						label="Invoice Total"
						style="margin-left:15px"
					>Invoice Total: <b>${{invoiceTotal}}</b></div>	
				</v-col>
			</v-row>
			<div>
			</div>
			<div v-if="!stInvoice" style="margin-left:15px">
				<v-row>
					<v-col>					
						RECEIPT
					</v-col>					
				</v-row>
				<v-row>
					<v-col>					
						Date: {{date}}
					</v-col>					
				</v-row>
				<v-row>
					<v-col>					
						Reference: {{quoteDetails.reference}}
					</v-col>					
				</v-row>
				<v-row style="margin-top:25px">
					<v-col>					
						To: {{quoteDetails.customersummary}}
					</v-col>					
				</v-row>		
				<v-row style="margin-top:25px">
					<v-col>					
						Tinting
					</v-col>					
					<v-col>					
						${{invoiceTotal}}
					</v-col>					
				</v-row>
				<v-btn 
					style="margin-top:15px"
					@click="generateReceiptPdf"
				>
					Print
				</v-btn>			
				<div style="margin-bottom:25px">
				</div>
			</div>
			<div v-if="stInvoice" style="margin-left:15px">
						<v-btn
							class="mx-2"
							dark
							color="success"
							@click="generateInvoicePdf"
							style="margin-top:5px"
						>
							Invoice
						</v-btn>
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-2"
							dark
							color="indigo"
							v-bind="attrs"
							v-on="on"
							@click="setPaid"
							style="margin-top:5px"
						>
							Paid
						</v-btn>
					</template>
					<span>Set paid</span>
				</v-tooltip>
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-2"
							dark
							color="error"
							v-bind="attrs"
							v-on="on"
							@click="setCancel"
							style="margin-top:5px"
						>
							Cancelled
						</v-btn>
					</template>
					<span>Set Cancelled</span>
				</v-tooltip>
			</div>
		</v-tab-item>				
    </v-tabs>
  </v-card>	

  <v-dialog
		v-model="scheduleDialog"
		transition="dialog-top-transition"
		max-width="600px"
		style="width:70%;"
	>
		<template>
			<v-card>
				<v-toolbar
					color="primary"
					dark
				>Create/Update Schedule</v-toolbar>
				<div style="margin-top:25px;margin-left:45px;margin-right:15px;">
					<v-row>
						<v-col cols=12>
							<v-text-field 
								v-model="scheduleDescription"
								label="Description"
							/>
						</v-col>
					</v-row>
				</div>
				<div style="margin-top:-25px;margin-left:45px;margin-right:15px;">
					<v-row>
						<v-col cols=12>
							<v-combobox
								v-model="this.installer"
								:items="['Ben', 'Sumin', 'Installer 1', 'Installer 2', 'Installer 3', 'Installer 4', 'Installer 5']"
								label="Installed by"
							></v-combobox>
						</v-col>
					</v-row>
				</div>
				<div style="margin:15px;">
					<v-menu
						v-model="menu2"
						:close-on-content-click="false"
						:nudge-right="40"
						transition="scale-transition"
						offset-y
						min-width="auto"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="scheduleDate"
								label="Date"
								prepend-icon="mdi-calendar"
								readonly
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="scheduleDate"
							@input="menu2 = false"
						>
						</v-date-picker>
					</v-menu>
					<v-range-slider
							v-model="scheduleTime"
							thumb-label="always"
							min="8"
							max="19"
							style="margin-top:35px;"
							label="Time"
						>
					</v-range-slider>						
				</div>
				<v-card-actions class="justify-end">
					<v-tooltip top v-if="scheduleDate">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								class="mx-2"
								fab
								dark
								color="success"
								v-bind="attrs"
								v-on="on"
								@click="saveSchedule"
								style="margin-top:5px"
							>
								<v-icon>
									mdi-content-save
								</v-icon>
							</v-btn>
						</template>
						<span>Create/Update schedule</span>
					</v-tooltip>
					<v-tooltip top v-if="quoteDetails.scheduledDate">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								class="mx-2"
								fab
								dark
								color="error"
								v-bind="attrs"
								v-on="on"
								@click="deleteSchedule"
								style="margin-top:5px"
							>
								<v-icon>
									mdi-delete
								</v-icon>
							</v-btn>
						</template>
						<span>Delete this schedule</span>
					</v-tooltip>
					<v-tooltip top v-if="installItem.index>=0||true">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								class="mx-2"
								dark
								color="warning"
								v-bind="attrs"
								v-on="on"
								@click="closeScheduleDialog"
								style="margin-top:5px"
							>
								Close
							</v-btn>
						</template>
						<span>Update this item</span>
					</v-tooltip>					
				</v-card-actions>
			</v-card>
		</template>
	</v-dialog>					
  
	<v-dialog
		v-model="installDialog"
		transition="dialog-top-transition"
		max-width="600px"
	>
		<template>
			<v-card>
				<v-toolbar
					color="primary"
					dark
				>Install details</v-toolbar>
				<div style="margin:5px;">
					<v-row>
						<v-col cols=12>
							<v-select
								v-model="installItem.option"
								:counter="120"
								label="Installation"
								:items="installOptions"
								multiple
							></v-select>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols=12>
							<v-text-field 
								v-model="installItem.description"
								label="Description"
							/>
						</v-col>
					</v-row>
					<v-tooltip top v-if="installItem.index===-1">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								class="mx-2"
								fab
								dark
								color="indigo"
								v-bind="attrs"
								v-on="on"
								@click="addInstallItem"
								style="margin-top:5px"
							>
								<v-icon dark>
									mdi-plus
								</v-icon>
							</v-btn>
						</template>
						<span>Save this item</span>
					</v-tooltip>
					<v-tooltip top v-if="installItem.index>=0">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								class="mx-2"
								dark
								color="success"
								v-bind="attrs"
								v-on="on"
								@click="addInstallItem"
								style="margin-top:5px"
							>
								Update
							</v-btn>
						</template>
						<span>Update this item</span>
					</v-tooltip>
				</div>
				<v-card-actions class="justify-end">
					<v-btn
						text
						@click="installDialog = false"
					>Close</v-btn>
				</v-card-actions>
			</v-card>
		</template>
	</v-dialog>					
	<v-dialog
		v-model="quoteAddDialog"
		transition="dialog-top-transition"
		max-width="600px"
	>
		<template>
			<v-card>
				<v-toolbar
					color="primary"
					dark
				>Quote line</v-toolbar>
				<div style="margin:15px;">
					<v-row>
						<v-col cols=12>
							<v-select
								v-model="quoteLineItem.option"
								:counter="120"
								label="Quote option"
								:items="pricingOptions"
								@input="quoteItemChange"
							></v-select>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols=12>
							<v-text-field 
								v-model="quoteLineItem.description"
								label="Description"
								maxlength="80"
								style="width:250px;"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-text-field 
								v-model="quoteLineItem.quantity"
								type="number"
								label="Quantity"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-text-field 
								v-model="quoteLineItem.price"
								type="number"
								label="Price"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col v-if="quoteLineItem.amount">
							Total ${{Number(quoteLineItem.quantity)*Number(quoteLineItem.price)}}
						</v-col>
					</v-row>
					<v-tooltip top v-if="quoteLineItem.index===-1">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								class="mx-2"
								fab
								dark
								color="indigo"
								v-bind="attrs"
								v-on="on"
								@click="addQuoteItem"
								style="margin-top:5px"
							>
								<v-icon dark>
									mdi-plus
								</v-icon>
							</v-btn>
						</template>
						<span>Save this item</span>
					</v-tooltip>
					<v-tooltip top v-if="quoteLineItem.index>=0">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								class="mx-2"
								dark
								color="success"
								v-bind="attrs"
								v-on="on"
								@click="addQuoteItem"
								style="margin-top:5px"
							>
								Update
							</v-btn>
						</template>
						<span>Update this item</span>
					</v-tooltip>
				</div>
				<v-card-actions class="justify-end">
					<v-btn
						text
						@click="quoteAddDialog = false"
					>Close</v-btn>
				</v-card-actions>
			</v-card>
		</template>
	</v-dialog>			

		<v-container v-if="false">
			<customerDetails  v-if="quoteDetails.customerid" :customerDetails="customer"></customerDetails>
		</v-container>

		<v-container v-if="false">

		<div v-if="false">
			<v-dialog
				v-model="quoteForDialog"
				transition="dialog-top-transition"
				max-width="600px"
			>
				<template v-slot:default="dialog">
					<v-card>
						<v-toolbar
							color="primary"
							dark
						>Tinting for</v-toolbar>
						
						<v-row>
							<v-col v-for="(item,index) in tintItems" v-bind:key="index" col=3>
								<a href="#" @click="selection(item,dialog)">
									<v-img
									alt="Vuetify Logo"
									contain
									:src="item.image"
									transition="scale-transition"
									width="100px"
									/>
								</a>
								<h6 v-if="quoteDetails.quoteFor.label===item.label" style="margin-top:15px;margin-left:25px;background-color:red;text-decoration-color: none !important; color: white;">{{item.label}}</h6>
								<h6 v-if="quoteDetails.quoteFor.label!==item.label" style="margin-top:15px;margin-left:25px;color: black;">{{item.label}}</h6>
							</v-col>
						</v-row>
						<v-card-actions class="justify-end">
							<v-btn
								text
								@click="quoteForDialog = false"
							>Close</v-btn>
						</v-card-actions>
					</v-card>
				</template>
			</v-dialog>			
		</div>

		<v-container v-if="quoteDetails.quoteFor.installtype!=='Automotive'">
			<v-row style="margin-top:30px;">
				<div>
					<v-img
					alt="Vuetify Logo"
					contain
					:src="quoteDetails.quoteFor.image"
					transition="scale-transition"
					width="140px"
					/>
				</div>
			</v-row>
			<v-container>
				<v-row style="margin-top:20px;">
					<h4>Tint</h4>
				</v-row>
				<v-row>
					<v-btn-toggle v-model="quoteDetails.premium" mandatory>
						<v-btn>
							Regular
						</v-btn>
						<v-btn>
							Premium
						</v-btn>
					</v-btn-toggle>		
				</v-row>
				<v-row style="margin-top:20px;">
					<h4>Removal of existing tint</h4>
				</v-row>
				<v-row>
					<v-btn-toggle v-model="quoteDetails.tintremoval" mandatory>
						<v-btn>
							Not Required
						</v-btn>
						<v-btn>
							Required
						</v-btn>
					</v-btn-toggle>		
				</v-row>
				<v-row>
					<v-col cols=3>
						<v-text-field
							v-model="quoteDetails.windows"
							type="number"
							label="Number of windows"
						></v-text-field>
					</v-col>	
					<v-col cols=3>
						<v-text-field
							v-model="quoteDetails.area"
							type="number"
							label="Total window area in m²"
						></v-text-field>
					</v-col>	
				</v-row>				
				<v-textarea
					v-model="quoteDetails.comment"
					label="Additional information that will help with a quote"
					style="margin-top:15px;"
				></v-textarea>	
			</v-container>		
		</v-container>
		<v-tooltip top>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					class="mx-2"
					fab
					dark
					color="indigo"
					v-bind="attrs"
					v-on="on"
					@click="saveData"
					style="margin-top:5px"
				>
					<v-icon dark>
						mdi-content-save
					</v-icon>
				</v-btn>
			</template>
			<span>Save changes</span>
		</v-tooltip>	
		</v-container>
	</div>
</template>

<script>
	import scheduleDetails from '@/components/scheduleDetails.vue'
	import services from '@/services/services.js'
	import customerDetails from '@/components/customerDetails.vue'
	import jsPDF from "jspdf";	
	export default {
	props: ['quoteDetails','customerDetails'],
	components: {
		customerDetails,
		scheduleDetails,
	},
	data() {
		return {
			afterFile:null,
			beforeFile:null,
			uploadError:"",
			uploadSuccess:"",
			eventSettings: {
				dataSource: [
					{
						Id: 1,
						Subject: 'Automotive',
						StartTime: new Date(2022, 9, 13, 8, 0),
						EndTime: new Date(2022, 9, 13, 16, 0),
						TypeId: 1
					},
					{
						Id: 2,
						Subject: 'Residential',
						StartTime: new Date(2022, 9, 13, 8, 0),
						EndTime: new Date(2022, 9, 13, 11, 30),
						TypeId: 2,
					},
					{
						Id: 3,
						Subject: 'Commercial',
						StartTime: new Date(2022, 9, 14, 8, 0),
						EndTime: new Date(2022, 9, 14, 11, 30),
						TypeId: 3,
					},
				]
			},
			scheduleDate:null,
			scheduleTime:[9,13],
			scheduleDescription:'',
			installer:'',
			showWeekend:false,
			showHeaderBar:false,
			headers: [
				{ text: 'Description', align: 'start', value: 'description' },
			],
			source2: 'https://test.iotx3.com/pdf',
			invoiceTotal:99,
			useQuoteAmount:true,
			invoiceCustomer: true,
			creditPayment: false,
			cashPayment: false,
			stInvoice:true,
			menu2: false,
			date: null,
			refreshQuoteItems:false,
			quoteLineItem:{},
			installItem:{},
			quoteSettings:{},
			quoteForDialog:false,
			quoteAddDialog:false,
			scheduleDialog:false,
			installDialog:false,
			manufacturers: [
					"Audi",
					"BMW",
					"Ford",
					"Holden",
					"Honda",
					"Hyundai",
					"Kia",
					"Mazda",
					"Mitsubishi",
					"Nissan",
					"Toyota",
					"Chrysler",
					"Citroen",
					"Daihatsu",
					"Dodge",
					"Ferrari",
					"Fiat",
					"Infiniti",
					"Isuzu",
					"Jeep",
					"Land Rover",
					"Lexus",
					"Mahindra",
					"Maserati",
					"Mercedes-Benz",
					"MINI",
					"Opel",
					"Peugeot",
					"Proton",
					"Renault",
					"Skoda",
					"Ssangyong",
					"Subaru",
					"Suzuki",
					"Volkswagen",
					"Volvo",
					"Yamaha",
			],
			tintItems:[], //list of items that can be tinted from API call at startup
			tintDetails:{},
			pricingOptions:[],
			installOptions:[
				"All",
				"Front",
				"Back",
				"Sides",
				"Passenger Side",
				"Drivers Side"				
			],
			lastValue:[],
			weekend:"",
		}
    },    
	methods: {
		closeScheduleDialog()
		{
			this.scheduleDialog = false;
		},
		showScheduleDialog()
		{
			this.scheduleDescription=this.quoteDetails.model;
			if (this.quoteDetails.registration)
				this.scheduleDescription=this.quoteDetails.registration+": "+this.quoteDetails.model;
			this.scheduleDialog=true;
		},
		saveSchedule()
		{
			if (this.quoteDetails.scheduledDate!==this.scheduleDate||this.quoteDetails.scheduledTime!==this.scheduleTime)
			{
				this.quoteDetails.scheduledDate=this.scheduleDate;
				this.quoteDetails.scheduledTime=this.scheduleTime;

				var timezoneOffset=new Date(2022,1,1);
				if (new Date(this.scheduleDate).getDay()===6||new Date(this.scheduleDate).getDay()===0)
					this.weekend="****WEEKEND";
				else	
					this.weekend="";
				var startTime=new Date(this.scheduleDate)-1+1+(timezoneOffset.getTimezoneOffset()*60*1000)+(this.scheduleTime[0])*60*60*1000;
				var endTime=new Date(this.scheduleDate)-1+1+(timezoneOffset.getTimezoneOffset()*60*1000)+(this.scheduleTime[1])*60*60*1000;
				var typeId=1;
				if (this.quoteDetails.quoteFor.label==="Residential")
					typeId=2;
				if (this.quoteDetails.quoteFor.label==="Commercial")
					typeId=3;
				
				var data={};
				data._id=this.quoteDetails._id;
				data.customerid=this.quoteDetails.customerid;
				data.Subject=this.scheduleDescription;
				data.installer=this.installer;
				data.StartTime=startTime;
				data.EndTime=endTime;
				data.TypeId=typeId;
				services.updateSchedule({data:data,token:this.$store.state.token}).then(()=>{
					var scheduleObj=document.getElementById('schedule').ej2_instances[0];
					scheduleObj.addEvent(data);
					scheduleObj.refreshEvents();
					var drange=this.lastValue;
					drange.start-=7*24*60*60*1000;
					drange.end-=7*24*60*60*1000;
					this.dateRangeChange(drange);
				}).catch(error=> { console.log(error)})
				services.updateQuote({data:this.quoteDetails,token:this.$store.state.token}).then(response=>{if (response.data.token) this.$store.commit('setToken',response.data.token);this.$emit('updatedQuote');}).catch(error=> { console.log(error)})
				//need to save the scheduledDate
			}
			this.scheduleDialog = false;
		},
		deleteSchedule()
		{
			var data={};
			var futureSchedule=true;
			
			if (new Date(this.scheduleDate)<new Date())
				futureSchedule=false;

			if (futureSchedule)
			{
				data._id=this.quoteDetails._id;

				services.deleteSchedule({data:data,token:this.$store.state.token}).then(response=>{
						if (response.data.result==="ok")
						{
							this.quoteDetails.scheduledDate=null;
							this.saveData();

							var value=this.lastValue;
							value.token=this.$store.state.token;
							services.getSchedules(value).then(response=>{
								if (response.data.token) 
									this.$store.commit('setToken',response.data.token);
								var newSchedule=[];
								if (response.data.data)
								{
									var id=1;
									response.data.data.forEach(function(item)
									{
										var newItem={};
										newItem.EndTime=new Date(item.EndTime);
										newItem.Id=id++;
										newItem.StartTime=new Date(item.StartTime);
										newItem.Subject=item.Subject;
										newItem.TypeId=item.TypeId;
										newSchedule.push(newItem);
									});
									this.updateSchedule(newSchedule);
							}
							}).catch(error=> { console.log(error)})
						}	
					}).catch(error=> { console.log(error)})

				this.scheduleDialog = false;
			}
		},
		dateRangeChange(value)
		{
			this.lastValue=value;
			value.token=this.$store.state.token;
			services.getSchedules(value).then(response=>{
					if (response.data.token) 
						this.$store.commit('setToken',response.data.token);
					var newSchedule=[];
					if (response.data.data)
					{
						var id=1;
						response.data.data.forEach(function(item)
						{
							var newItem={};
							newItem.EndTime=new Date(item.EndTime);
							newItem.Id=id++;
							newItem.StartTime=new Date(item.StartTime);
							newItem.Subject=item.Subject;
							newItem.TypeId=item.TypeId;
							newSchedule.push(newItem);
						});
						this.updateSchedule(newSchedule);
					}
					
				}).catch(error=> { console.log(error)})
		},
		updateSchedule(newSchedule)
		{
			this.eventSettings.dataSource=[];
			this.eventSettings.dataSource=newSchedule;
			var scheduleObj=document.getElementById('schedule').ej2_instances[0];
			scheduleObj.eventSettings.dataSource = this.eventSettings.dataSource;
			scheduleObj.refreshEvents();
		},
		checkPayment()
		{
			if (!this.creditPayment)
				this.cashPayment=true;
		},
		onPopupOpen: function(args) {
			args.cancel = true;
		},
		eventAction(args)
		{
			if (args.requestType==="eventChanged")
				console.log(args.changedRecords[0]);
		},
		updateInvoiceTotal()
		{
			var invoiceAmount=0;
			if (this.quoteDetails.quoteItems && this.quoteDetails.quoteItems.length>0)
			{
				this.quoteDetails.quoteItems.forEach(function(item){
					invoiceAmount+=Number(item.amount);
				});
			}
			else
			{
				invoiceAmount=this.quoteDetails.priceGuide;
			}
			this.invoiceTotal=invoiceAmount;	
		},
		deleteItem(index)
		{
			var newItems=[];
			for (var i=0;i<this.quoteDetails.quoteItems.length;i++)
			{
				if (i!=index)
					newItems.push(this.quoteDetails.quoteItems[i]);
			}
			this.quoteDetails.quoteItems=newItems;
			this.updateInvoiceTotal();
		},
		editItem(index)
		{
			this.quoteLineItem=this.quoteDetails.quoteItems[index];
			this.quoteLineItem.index=index;
			this.quoteAddDialog=true;
			this.updateInvoiceTotal();
		},
		deleteInstallItem(index)
		{
			var newItems=[];
			for (var i=0;i<this.quoteDetails.installItems.length;i++)
			{
				if (i!=index)
					newItems.push(this.quoteDetails.installItems[i]);
			}
			this.quoteDetails.installItems=newItems;
		},
		editInstallItem(index)
		{
			this.installItem=this.quoteDetails.installItems[index];
			this.installItem.index=index;
			this.installDialog=true;
		},
		addInstall()
		{
			this.installItem={};
			this.installItem.index=-1;
			this.installDialog=true;
		},
		addInstallItem()
		{
			if (this.installItem.index===-1)
			{
				if (!this.quoteDetails.installItems)
					this.quoteDetails.installItems=[];
				this.quoteDetails.installItems.push(this.installItem);
			}
			this.installDialog=false;
		},
		uploadImageAfter(event)
		{
			var dateTime=new Date();

			this.uploadImage(event,"after_"+this.quoteDetails._id+"_"+dateTime.getTime(),false)
		},
		uploadImageBefore(event)
		{
			var dateTime=new Date();

			this.uploadImage(event,"before_"+this.quoteDetails._id+"_"+dateTime.getTime(),true)
		},
		uploadImage(event, name, before) 
		{
			let imageData = null;
			let context = null;
			let maxWidth = 1024;
			let maxHeigt = 768;
			let width = maxWidth;
			let height = maxHeigt;
			let vm = this;
			let files = event;
			vm.imageIndex = 0;

			let formData = new FormData();
			var me=this;
			for (let i = 0; i < files.length; i++) {
				let reader = new FileReader();
				let file = files[i];
				if (!file.type.match('image')) continue;
				if (file) {
					var img = document.createElement("img");
					var canvas =document.createElement("canvas");
					reader.onload = function (e) {
						img.src = e.target.result;

					};
					reader.readAsDataURL(file);
					img.onload = function () {
						// Draw
						context = canvas.getContext('2d');
						context.drawImage(img, 0, 0);
						if (img.width > maxWidth || img.height > maxHeigt) {
							width = maxWidth;
							height = maxHeigt;
							var ration = maxWidth / img.width;

							if (img.width > maxWidth) {
								width = maxWidth;
								height = Math.round(img.height * ration);
							}

							if (img.height > maxHeigt) {
								height = maxHeigt;
								width = Math.round(img.width * ration);
							}
						} else {
							width = img.width;
							height = img.height;
						}
						canvas.width = width;
						canvas.height = height;

						context.drawImage(img, 0, 0, width, height);
						imageData = canvas.toDataURL('image/jpeg');
						imageData.replace('data:image/jpeg;base64,', '');

						let blobToFile = vm.dataURItoBlob(imageData);
						const cfile = new File([blobToFile], name + ".jpg",
							{type: "image/jpeg", lastModified: Date.now()});
						formData.append('ad_image', cfile);
						services.uploadImage(formData).then(response=>{
							if (response.data.status&&response.data.status==="ok")
							{
								if (!(me.quoteDetails&&me.quoteDetails.photos))
								{
									me.quoteDetails.photos={before:[],after:[]}
								}
								if (before)
								{
									me.quoteDetails.photos.before.push({filename:response.data.path,size:cfile.size});
									me.beforeFile={};
								}
								else
								{
									me.quoteDetails.photos.after.push({filename:response.data.path,size:cfile.size});
									me.afterFile={};
								}
								me.saveData();
								me.uploadSuccess="File upload completed successfully";
								setTimeout(function(){me.uploadSuccess="";},5000)
							}
							if (response.data.status&&response.data.status==="error") 
							{
								me.uploadError="Error uploading file";
								me.afterFile={};
								me.beforeFile={};
							}
						}).catch(error=> { console.log(error)});
					};
				}
			}
		},
		moveAfter(image)
		{
			this.quoteDetails.photos.after.push(image);
			var tempBefore=[];
			this.quoteDetails.photos.before.forEach(function(item){
				if (item.filename!==image.filename)
					tempBefore.push(item);
			});
			this.quoteDetails.photos.before=tempBefore;
			this.saveData();
		},
		moveBefore(image)
		{
			this.quoteDetails.photos.before.push(image);
			var tempAfter=[];
			this.quoteDetails.photos.after.forEach(function(item){
				if (item.filename!==image.filename)
					tempAfter.push(item);
			});
			this.quoteDetails.photos.after=tempAfter;
			this.saveData();
		},
		deleteImage(image,before)
		{
			services.deleteImage(image.filename).then(response=>{
							if (response.data.status&&response.data.status==="ok")
							{
								if (before)
								{
									var tempBefore=[];
									this.quoteDetails.photos.before.forEach(function(item){
										if (item.filename!==image.filename)
											tempBefore.push(item);
									});
									this.quoteDetails.photos.before=tempBefore;
									this.saveData();
								}
								else
								{
									var tempAfter=[];
									this.quoteDetails.photos.after.forEach(function(item){
										if (item.filename!==image.filename)
											tempAfter.push(item);
									});
									this.quoteDetails.photos.after=tempAfter;
									this.saveData();
								}
							}
						}).catch(error=> { console.log(error)});
		},
		dataURItoBlob(dataURI) {
			// convert base64 to raw binary data held in a string
			var byteString = atob(dataURI.split(',')[1]);
			// separate out the mime component
			var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
			// write the bytes of the string to an ArrayBuffer
			var arrayBuffer = new ArrayBuffer(byteString.length);
			var _ia = new Uint8Array(arrayBuffer);
			for (var i = 0; i < byteString.length; i++) {
				_ia[i] = byteString.charCodeAt(i);
			}
			var dataView = new DataView(arrayBuffer);
			var blob = new Blob([dataView], {type: mimeString});
			return blob;
		},		
		addQuote()
		{
			if (this.quoteDetails.quoteFor.installtype==="Automotive")
			{
				this.pricingOptions=["Regular Tint","Premium Tint","Tint Removal","Mobile Installation",
										"Tint Single Window","Tint Small Window Section","Front/Back Tint Removal","Single Window Tint Removal",
										"Additional Pricing","Comment"]
			}
			else
			{
				this.pricingOptions=["Regular tint","Premium Tint","Tint Removal","Additional Pricing","Comment"]
			}
			this.quoteAddDialog=true;
			this.quoteLineItem={index:-1};
		},
		addQuoteItem() {
			this.quoteLineItem.amount=Number(this.quoteLineItem.price)*Number(this.quoteLineItem.quantity)
			if (this.quoteLineItem.index===-1)
			{
				if (!this.quoteDetails.quoteItems)
					this.quoteDetails.quoteItems=[];
				this.quoteDetails.quoteItems.push(this.quoteLineItem);
			}
			this.quoteAddDialog=false;

			this.updateInvoiceTotal();
		},
		selection(selection,dialog)
		{	
			this.quoteDetails.quoteFor=selection;
			dialog.value=false;
		},
		saveData(){
			if (this.quoteDetails.installlocation===0)
				this.quoteDetails.installText="Penrith";
			if (this.quoteDetails.installlocation===2)
				this.quoteDetails.installText="Mobile";

			services.updateQuote({data:this.quoteDetails,token:this.$store.state.token}).then(response=>{if (response.data.token) this.$store.commit('setToken',response.data.token);this.$emit('updatedQuote');}).catch(error=> { console.log(error)})
		},
		setCancel(){
			this.quoteDetails.status="Cancelled";
			this.saveData();
		},
		setQuoted()
		{
			this.quoteDetails.status="Quoted";
			this.saveData();
		},
		setPriceGuide()
		{
			this.quoteDetails.status="Price Guide";
			this.saveData();
		},
		setInvoice()
		{
			this.quoteDetails.status="Invoiced";
			this.saveData();
		},
		setPaid()
		{
			this.quoteDetails.status="Paid";
			this.saveData();
		},
		setScheduled()
		{
			this.quoteDetails.status="Scheduled";
			this.saveData();
		},
		checkStoredValues() {
			if (!this.quoteDetails.pricepermetre)
				this.quoteDetails.pricepermetre=this.tintDetails.pricepermetre;
			if (!this.quoteDetails.tintremovalPrice)
				this.quoteDetails.tintremovalPrice=this.tintDetails.tintremoval;
			if (!this.quoteDetails.minimumprice)
				this.quoteDetails.minimumprice=this.tintDetails.minimumprice;		
		},
		quoteItemChange() 
		{
			this.quoteLineItem.description=this.quoteLineItem.option;this.quoteLineItem.quantity="";
			this.quoteLineItem.amount="";this.quoteLineItem.price="";
			if (this.quoteLineItem.option==="Regular Tint")
			{
				this.quoteLineItem.description=this.quoteLineItem.option;this.quoteLineItem.quantity=1;
				this.quoteLineItem.amount=Number(this.tintDetails.price);this.quoteLineItem.price=this.quoteLineItem.amount;
			}
			if (this.quoteLineItem.option==="Premium Tint")
			{
				this.quoteLineItem.description=this.quoteLineItem.option;this.quoteLineItem.quantity=1;
				this.quoteLineItem.amount=Number(this.tintDetails.price)+Number(this.tintDetails.premium);this.quoteLineItem.price=this.quoteLineItem.amount;
			}
			if (this.quoteLineItem.option==="Tint Removal")
			{
				this.quoteLineItem.description=this.quoteLineItem.option;this.quoteLineItem.quantity=1;
				this.quoteLineItem.amount=Number(this.tintDetails.tintremoval);this.quoteLineItem.price=this.quoteLineItem.amount;
			}			
			if (this.quoteLineItem.option==="Mobile Installation")
			{
				this.quoteLineItem.description=this.quoteLineItem.option;this.quoteLineItem.quantity=1;
				this.quoteLineItem.amount=Number(this.tintDetails.offsite);this.quoteLineItem.price=this.quoteLineItem.amount;
			}			
		},
		generateReceiptPdf()
		{
			var displayDate=this.date;
			//day is before the first -
			var day=displayDate.substring(0,displayDate.indexOf("-"));
			//month is before the next - and before the next -
			var month=displayDate.substring(displayDate.indexOf("-")+1);
			//year is after the last -
			var year=month.substring(month.indexOf("-")+1);
			//remove everything after the - in month
			month=month.substring(0,month.indexOf("-"));
			/*.substring(0,4);
			var month=this.date.substring(5);
			var day=month.substring(month.indexOf("-")+1);
			if (month.indexOf("-")>0)
				month=month.substring(0,month.indexOf("-"));*/
			displayDate=day+"/"+month+"/"+year;

			let pdfName = 'receipt'; 
			var doc = new jsPDF();
			var ypos=20;
			doc.setFontSize(24);
			doc.text("RECEIPT", 10, ypos);
			doc.setFontSize(12);
			ypos+=20;
			doc.text("Date",10,ypos);
			doc.text(displayDate,50,ypos);
			ypos+=10;
			doc.text("Reference",10,ypos);
			doc.text(this.quoteDetails.reference,50,ypos);
			ypos+=30;
			doc.text("To",10,ypos);
			doc.text(this.quoteDetails.customersummary,50,ypos);
			ypos+=30;
			doc.text("Tinting",10,ypos);
			doc.text("$"+this.invoiceTotal,160,ypos);			
			//doc.save(pdfName + '.pdf');
			window.open(doc.output('bloburl', { filename: pdfName+'.pdf' }), '_blank');
		},
		generateInvoicePdf()
		{
			this.quoteDetails.invoiceDate=this.date;
			this.quoteDetails.installer=this.installer;
			this.setInvoice();
			var displayDate=this.date;
			//day is before the first -
			var day=displayDate.substring(0,displayDate.indexOf("-"));
			//month is before the next - and before the next -
			var month=displayDate.substring(displayDate.indexOf("-")+1);
			//year is after the last -
			var year=month.substring(month.indexOf("-")+1);
			//remove everything after the - in month
			month=month.substring(0,month.indexOf("-"));
			/*.substring(0,4);
			var month=this.date.substring(5);
			var day=month.substring(month.indexOf("-")+1);
			if (month.indexOf("-")>0)
				month=month.substring(0,month.indexOf("-"));*/
			displayDate=day+"/"+month+"/"+year;
			let pdfName = 'receipt'; 
			var doc = new jsPDF();

			var img = new Image();
			img.src = '/solartint-1.png';
			doc.addImage(img, 'png', 10, 10, 40, 15);			

			doc.setFontSize(24);
			doc.text("Tax Invoice", 150, 15);
			doc.setFontSize(16);
			doc.text(this.quoteDetails.reference, 150, 23);
			doc.setFontSize(16);
			doc.setFontSize(10);
			var ypos=35;
			doc.text("ABN 56 620 840 717", 10, ypos);
			ypos+=5;
			doc.text("6/37-47 Borec Road, Penrith NSW 2750", 10, ypos);
			ypos+=5;
			doc.text("garagemethodsales@outlook.com", 10, ypos);
			ypos+=5;
			doc.text("0450245460", 10, ypos);
			ypos+=5;
			doc.text("ABN 56 620 840 717", 10, ypos);
			ypos+=5;
			doc.setFontSize(12);
			doc.text("", 10, ypos);
			
			ypos+=15;
			doc.setFontSize(12);
			doc.text("Date",10,ypos);
			doc.text(displayDate,50,ypos);
			ypos+=10;
			if (this.quoteDetails.installer)
			{
				doc.text("Installed by",10,ypos);
				doc.text(this.quoteDetails.installer,50,ypos);
				ypos+=10;
			}
			if (this.quoteDetails.registration)
			{
				doc.text("Registration",10,ypos);
				doc.text(this.quoteDetails.registration,50,ypos);
				ypos+=10;
			}
			doc.text("Invoice To",10,ypos);
			doc.text(this.quoteDetails.customersummary,50,ypos);
			ypos+=15;
			doc.setFillColor(153, 0,0);
			doc.rect(10, ypos, 190, 7, "F");		
			doc.setTextColor(255,255,255);			
			doc.text("Service",10,ypos+5);			
			doc.text("Amount",165,ypos+5);			
			doc.setTextColor(0,0,0);			
			ypos+=12;

			doc.setFontSize(12);
			this.quoteDetails.quoteItems.forEach(function(item)
			{console.log(item);
				doc.text(item.description,10,ypos);
				//set item amount to a number
				item.amount=Number(item.amount);
				doc.text("$"+item.amount.toFixed(2),165,ypos);			
				ypos+=15;
			});
			doc.text("Invoice Total",10,ypos);
			doc.text("$"+this.invoiceTotal.toFixed(2),165,ypos);			
			ypos+=8;
			doc.text("GST (included in total)",10,ypos);
			doc.text("$"+(this.invoiceTotal-this.invoiceTotal/1.1).toFixed(2),165,ypos);			

			if (this.creditPayment)
			{
				ypos+=15;
				doc.text("Paid - credit card",10,ypos);
			}
			if (this.cashPayment)
			{
				ypos+=15;
				doc.text("Paid - cash",10,ypos);
			}
			var img2 = new Image();
			img2.src = '/solartint-1.png';
			doc.addImage(img2, 'png', 10, 270, 40, 15);			
			
			//doc.save(pdfName + '.pdf');
			window.open(doc.output('bloburl', { filename: pdfName+'.pdf' }), '_blank');
		},
	},
	watch: {
		quoteDetails: function(newVal, oldVal) {
			var config={collection:"tinting-quoting",filter:newVal.quoteFor._id,token:this.$store.state.token};
			if (newVal.quoteFor.installtype==="Automotive" && newVal.quoteFor.installtype!=oldVal.quoteFor.installtype)
			{
				this.pricingOptions=["Regular Tint","Premium Tint","Tint Removal","Mobile Installation",
										"Tint Single Window","Tint Small Window Section","Front/Back Tint Removal","Single Window Tint Removal",
										"Additional Pricing","Comment"]
			}
			else
			{
				this.pricingOptions=["Regular tint","Premium Tint","Tint removal","Additional Pricing","Comment"]
			}

			this.refreshQuoteItems=false;
			services.getSingleCollectionItem(config).then(response=>{
				if (response.data.token && response.data.result==="ok") this.$store.commit('setToken',response.data.token); else this.$store.commit('setToken',"");
				if (response.data.data) this.tintDetails=response.data.data;
				this.checkStoredValues();
				if (response.data.token) 
					this.$store.commit('setToken',response.data.token);
				if (!newVal.quoteItems||newVal.quoteItems.length<=0)
				{
					if (newVal.quoteFor.installtype==="Automotive")
					
					{
						this.quoteDetails.quoteItems=[];
						var quoteItem={option:"Regular Tint",description:"Regular Tint",quantity:1,price:this.tintDetails.price,amount:this.tintDetails.price};
						if (newVal.premium===1)
						{
							quoteItem={option:"Premium Tint",description:"Premium Tint",quantity:1,price:Number(this.tintDetails.price)+Number(this.tintDetails.premium),amount:Number(this.tintDetails.price)+Number(this.tintDetails.premium)};
						}
						this.quoteDetails.quoteItems.push(quoteItem);
						if (newVal.tintremoval===1)
						{
							quoteItem={option:"Tint Removal",description:"Tint Removal",quantity:1,price:this.tintDetails.tintremoval,amount:this.tintDetails.tintremoval};
							this.quoteDetails.quoteItems.push(quoteItem);
						}
						if (newVal.installlocation===2)
						{
							quoteItem={option:"Mobile Installation",description:"Mobile Installation",quantity:1,price:this.tintDetails.offsite,amount:this.tintDetails.offsite};
							this.quoteDetails.quoteItems.push(quoteItem);
						}
						newVal.quoteDetails=this.quoteDetails.quoteItems;
					}
				}
				this.refreshQuoteItems=true;
			});
			
			this.updateInvoiceTotal();
		},
	},
	computed: {
		accessLevel() {
			return this.$store.state.accessLevel;
		},
		years() {
			var temp=[];
			var now=new Date();
			var last=now;
			for (var i=now.getFullYear()+1;i>=(now.getFullYear()-30);i--)
			{
				temp.push(i);
				last=i;
			}
			temp.push("Older than "+last);

			return temp;
		},	
	},	
	created() {
		var config={collection:"tinting-quoting",filter:this.quoteDetails.quoteFor._id, token:this.$store.state.token};
		services.getSingleCollectionItem(config).then(response=>{if (response.data.data) this.tintDetails=response.data.data;this.checkStoredValues();if (response.data.token) this.$store.commit('setToken',response.data.token); else this.$store.commit('setToken',"");});
		services.getTintQuoting().then(response=>{
			if (response.data.data)
			{
				this.tintItems=response.data.data;
			}
		}).catch(error=> { console.log(error)});

		this.fleetCount=[];
		for (var i=1;i<=25;i++)
		{
			this.fleetCount.push(i);
		}
		this.fleetCount.push(">25");
		this.updateInvoiceTotal();

		if (this.quoteDetails.installText==="")
		{
			if (this.quoteDetails.installlocation===0)
				this.quoteDetails.installText="Penrith";
			if (this.quoteDetails.installlocation===2)
				this.quoteDetails.installText="Mobile";
		}
		var curDate=new Date();
		this.date=curDate.getDate()+"-"+(curDate.getMonth()+1)+"-"+curDate.getFullYear();
		
		this.cashPayment=true;
		this.creditPayment=false;
		this.weekend="";
		if (this.quoteDetails.scheduledDate)
		{
			this.scheduleDate=this.quoteDetails.scheduledDate;
			this.scheduleTime=this.quoteDetails.scheduledTime;	
			if (new Date(this.scheduleDate).getDay()===6||new Date(this.scheduleDate).getDay()===0)
				this.weekend="****WEEKEND";
		}
		else
		{
			this.scheduleDate=null;
			this.scheduleTime=[9,12];
		}
		this.installer=this.quoteDetails.installer;

	},	
  }
</script>

<style>
</style>