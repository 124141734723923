<template>
  <v-container>
		<v-container>
			<img v-if="selectedImageCar" style="width:33%" :src="selectedImageCar" alt="">  
			<img v-if="selectedImageCar2" style="width:33%" :src="selectedImageCar2" alt="">
			<img v-if="selectedImageCar3" style="width:33%" :src="selectedImageCar3" alt="">
		</v-container>
		<v-row class="md-12">
			<v-col cols=3>
				<v-btn color="green" style="margin-top:15px;width:100%;" href="/quote">
					Get a Quote
				</v-btn>			
			</v-col>
		</v-row>
		
		<div class="md-12" style="display:block;margin-left:10px;margin-top:10px;">
			<h3>Black Envy - Non Reflective</h3>
			<img style="float:right;width:33%" src="/BlackEnvy.webp" alt="">  
			<p>You will experience heat rejection and glare reduction so you will never have too worry about uncomfortable journeys ever again!
				<ul>
					<li>99% UV Protection</li>
					<li>59% Glare Reduction</li>
					<li>100% Black</li>
				</ul>
			</p>
		</div>
		<div class="md-12" style="display:block;margin-left:10px;margin-top:25px;">
			<h3>Black Envy - High Performance</h3>
			<img style="float:right;width:33%" src="/BlackEnvyHighPerfomance.webp" alt="">  
			<p style="">Hybrid-metallic composition eliminates radio interference, allowing you to drive without interruption and maximise the efficiency of your technological devices
				<ul>
					<li>99% UV Protection</li>
					<li>63% Glare Reduction</li>
					<li>43% Heat Reduction</li>
				</ul>				
			</p>
		</div>
		<div class="md-12" style="display:block;margin-left:10px;">
			<h3>Black Envy - Premium</h3>
			<img style="float:right; width:33%" src="/BlackEnvyPremium.webp" alt="">  
			<p style="">We have the experience and skills necessary to tackle just about every type of job that comes our way. With Solartint Penrith, clients know exactly what to expect - professionalism, efficiency and exceptional results.
				<ul>
					<li>99% UV Protection</li>
					<li>88% Infrared Rejection</li>
					<li>100% Customer Satisfaction</li>
				</ul>				
			</p>
		</div>
	</v-container>
</template>

<script>
	import services from '@/services/services.js'

	export default {
		name: 'automotive',    data: () => ({
		items: [
			'/car-1.jpg',
			'/car-2.jpg',
			'/car-3.jpg',
			'/car-4.jpg',
			'/car-5.jpg',
			'/car-6.jpg',
			'/car-7.jpg',
			'/car-8.jpg',
			'/car-9.jpg',
			'/car-10.jpg',
			'/car-11.jpg',
			'/car-12.jpg',
			'/car-13.jpg',
			'/car-14.jpg',
			'/car-15.jpg',
			'/car-16.jpg',
			'/car-17.jpg',
			'/car-18.jpg',
			'/car-19.jpg',
			'/car-20.jpg',
			'/car-21.jpg',
			'/car-22.jpg',
			'/car-23.jpg',
			'/car-24.jpg',
			'/car-25.jpg',
			'/car-26.jpg',
			'/car-27.jpg',
			'/car-28.jpg',
			'/car-29.jpg',
			'/car-30.jpg',
			'/car-31.jpg',
			'/car-32.jpg',
			'/car-33.jpg',
			'/car-34.jpg',
		],
		selectedImageCar: null,
		selectedImageOther: null
    }),
	methods: {
		randomItem (items) {
			return items[Math.floor(Math.random()*items.length)];
		}
	},	
	created() {
		var viewdata={page:"Automotive","count":1};
		services.updateStats(viewdata);
		this.selectedImageCar = this.randomItem(this.items);
		this.selectedImageCar2 = this.selectedImageCar;
		this.selectedImageCar3 = this.selectedImageCar;
		while (this.selectedImageCar===this.selectedImageCar2)
			this.selectedImageCar2 = this.randomItem(this.items);
		while (this.selectedImageCar===this.selectedImageCar3||this.selectedImageCar2==this.selectedImageCar3)
			this.selectedImageCar3 = this.randomItem(this.items);			
	}
  }
</script>
